import { IInterventionTypeState } from "./states"
import { Module } from "vuex"
import { InterventionTypeResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IInterventionTypeState = {
    interventionTypesList : Array<InterventionTypeResponseDto>(),
    interventionTypesListArchived : Array<InterventionTypeResponseDto>(),
    interventionType      : <InterventionTypeResponseDto>{},
    isLoadingInterventionTypesList    : false,
    isLoadingInterventionTypesListArchived    : false,
    isLoadingInterventionType    : false,
    isCreatingInterventionType   : false,
    isUpdatingInterventionType   : false,
    isDeletingInterventionType   : false,
    requestStateInterventionType : <IRequestState>{},
    isOpenSidebarInterventionType : false,
    isArchivingInterventionType : false,
}

export const InterventionTypeModule: Module<IInterventionTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}