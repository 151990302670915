import { MissionResponseDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { MissionMutations } from './mutations'
import { IMissionState } from './states'

export const actions: ActionTree<IMissionState, RootState> = {
  /**
   * It fetches the list of missions from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchMissionsList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(MissionMutations.SET_IS_LOADING_MISSIONS_LIST, true)
      commit(MissionMutations.SET_IS_LOADING_MISSIONS_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/Mission/GetMissions',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(MissionMutations.SET_MISSIONS_LIST, res.data)
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res)
          commit(MissionMutations.SET_IS_LOADING_MISSIONS_LIST, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err)
          commit(MissionMutations.SET_IS_LOADING_MISSIONS_LIST, false)
          reject(err)
        })
    })
  },
  async fetchCommonMissionsList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(MissionMutations.SET_IS_LOADING_COMMON_MISSIONS_LIST, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/Mission/GetCommonMissions',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(MissionMutations.SET_COMMON_MISSIONS_LIST, res.data)
          commit(MissionMutations.SET_REQUEST_STATE_COMMON_MISSION, res)
          commit(MissionMutations.SET_IS_LOADING_COMMON_MISSIONS_LIST, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(MissionMutations.SET_REQUEST_STATE_COMMON_MISSION, err)
          commit(MissionMutations.SET_IS_LOADING_COMMON_MISSIONS_LIST, false)
          reject(err)
        })
    })
  },
  async fetchCommonMissionsListArchived ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(MissionMutations.SET_IS_LOADING_COMMON_MISSIONS_LIST, true)
      axios
        .get(
        process.env.VUE_APP_API_ORISIS + 'Management/Mission/GetCommonMissionsArchived',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(MissionMutations.SET_COMMON_MISSIONS_LIST_ARCHIVED, res.data)
          commit(MissionMutations.SET_REQUEST_STATE_COMMON_MISSION, res)
          commit(MissionMutations.SET_IS_LOADING_COMMON_MISSIONS_LIST, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(MissionMutations.SET_REQUEST_STATE_COMMON_MISSION, err)
          commit(MissionMutations.SET_IS_LOADING_COMMON_MISSIONS_LIST, false)
          reject(err)
        })
    })
  },


  async fetchMission (
    { commit, rootGetters },
    missionId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(MissionMutations.SET_IS_LOADING_MISSION, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Management/Mission/GetMissionById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                missionId: missionId
              }
            }
          )
          .then(res => {
            commit(MissionMutations.SET_MISSION, res.data)
            commit(MissionMutations.SET_REQUEST_STATE_MISSION, res)
            commit(MissionMutations.SET_IS_LOADING_MISSION, false)
            resolve({ ...res.data, workspaceId: rootGetters.workspaceSelected.id })
          })
          .catch(err => {
            console.error(err)
            commit(MissionMutations.SET_REQUEST_STATE_MISSION, err)
            commit(MissionMutations.SET_IS_LOADING_MISSION, false)
            reject(err)
          })
    })
  },
 
  /**
   * It fetches the mission data from the API.
   * @param  - missionId: The missionId of the mission you want to fetch.
   * @param {string} missionId - string
   */ 
  async fetchMissionArchived (
    { commit, rootGetters },
    missionId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(MissionMutations.SET_IS_LOADING_MISSION, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/Mission/GetMissionArchivedById',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              missionId: missionId
            }
          }
        )
        .then(res => {
          commit(MissionMutations.SET_MISSION, res.data)
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res)
          commit(MissionMutations.SET_IS_LOADING_MISSION, false)

          resolve({ ...res.data, workspaceId: rootGetters.workspaceSelected.id })
        })
        .catch(err => {
          console.error(err)
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err)
          commit(MissionMutations.SET_IS_LOADING_MISSION, false)
          reject(err)
        })
    })
  },


  /**
   * It creates a new mission.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {MissionResponseDto} mission - MissionResponseDto
   */
   async createMission ( 
    { commit, rootGetters },
    mission: MissionResponseDto
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
    commit(MissionMutations.SET_IS_CREATING_MISSION, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Management/Mission/CreateMission',
        mission,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.commonMissionsList.unshift(res.data.data)
        commit(MissionMutations.SET_MISSIONS_LIST, rootGetters.missionsList)
        commit(MissionMutations.SET_REQUEST_STATE_MISSION, res)
        commit(MissionMutations.SET_IS_CREATING_MISSION, false)
        resolve(res.data.data)
      })
      .catch(err => {
        commit(MissionMutations.SET_REQUEST_STATE_MISSION, err)
        commit(MissionMutations.SET_IS_CREATING_MISSION, false)
        reject(err)
      })
    })
  },
  /**
   * Update an mission
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {MissionResponseDto} mission - MissionResponseDto
   */
  async updateMission ({ commit, rootGetters }, mission: MissionResponseDto) {
    return await new Promise((resolve, reject) => {
      commit(MissionMutations.SET_IS_UPDATING_MISSION, true)
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Management/Mission/UpdateMission`,
          mission,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            }
          }
        )
        .then(res => {
          rootGetters.commonMissionsList.splice(
            rootGetters.commonMissionsList.findIndex(elem => elem.id == mission.id),
            1,
            res.data.data
          )
          rootGetters.missionsList.splice(
            rootGetters.missionsList.findIndex(elem => elem.id == mission.id),
            1,
            res.data.data
          )
          commit(MissionMutations.SET_MISSIONS_LIST, rootGetters.missionsList)
          commit(MissionMutations.SET_COMMON_MISSIONS_LIST, rootGetters.commonMissionsList)
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res)
          commit(MissionMutations.SET_IS_UPDATING_MISSION, false)
          resolve(res.data.data)
        })
        .catch(err => {
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err)
          commit(MissionMutations.SET_IS_UPDATING_MISSION, false)
          reject(err)
        })
    })
  },

  async updateMissionIndex ({commit, rootGetters}, missions) {
    return await new Promise ((resolve, reject) => {
      commit(MissionMutations.SET_IS_UPDATING_MISSION)
      axios 
        .put(
          `${process.env.VUE_APP_API_ORISIS}Management/Mission/UpdateMissionIndex`,
          missions,
          {
            headers: {
              Authorization : `Bearer ${rootGetters.userToken}`
            }
          }
        )
        .then(res => {
          commit(MissionMutations.SET_IS_UPDATING_MISSION, false)
          resolve(res.data.data)
        })
        .catch(err =>{
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err)
          commit(MissionMutations.SET_IS_UPDATING_MISSION, false)
          reject(err)
        })
    })
  },

  /**
   * It deletes an mission from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} missionId - string
   */
  async deleteMission ({ commit, rootGetters }, missionId: string) {
    commit(MissionMutations.SET_IS_DELETING_MISSION, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Management/Mission/DeleteMission`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          missionId: missionId
        }
      })
      .then(res => {
        rootGetters.commonMissionsListArchived.splice(
          rootGetters.commonMissionsListArchived.findIndex(elem => elem.id == missionId),
          1
        )
        commit(MissionMutations.SET_MISSIONS_LIST, rootGetters.missionsList)
        commit(MissionMutations.SET_REQUEST_STATE_MISSION, res)
        commit(MissionMutations.SET_IS_DELETING_MISSION, false)
      })
      .catch(err => {
        commit(MissionMutations.SET_REQUEST_STATE_MISSION, err)
        commit(MissionMutations.SET_IS_DELETING_MISSION, false)
      })
  },

  async archiveMission (
    { commit, rootGetters },
    missionId: string
  ): Promise<void> {
    commit(MissionMutations.SET_IS_ARCHIVING_MISSION, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Management/Mission/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[missionId]
        }
      )
      .then(res => {
        rootGetters.commonMissionsListArchived.unshift(rootGetters.missionsList.find(elem => elem.id == missionId ))
        rootGetters.commonMissionsList.splice(
          rootGetters.commonMissionsList.findIndex(
            elem => elem.id == missionId
          ),
          1
        )
        commit(
          MissionMutations.SET_MISSIONS_LIST,
          rootGetters.missionsList
        )
        commit(MissionMutations.SET_REQUEST_STATE_MISSION, res)
        commit(MissionMutations.SET_IS_ARCHIVING_MISSION, false)
      })
      .catch(err => {
        console.error(err)
        commit(MissionMutations.SET_REQUEST_STATE_MISSION, err)
        commit(MissionMutations.SET_IS_ARCHIVING_MISSION, false)
      })
  },

  async restoreMission (
    { commit, rootGetters },
    missionId: string
  ): Promise<void> {
    commit(MissionMutations.SET_IS_UPDATING_MISSION, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Management/Mission/Restore',
        [missionId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.missionsList.unshift(rootGetters.missionsListArchived.find(elem => elem.id == missionId ))
        rootGetters.missionsListArchived.splice(
          rootGetters.missionsListArchived.findIndex(
            elem => elem.id == missionId
          ),
          1
        )
        commit(MissionMutations.SET_REQUEST_STATE_MISSION, res)
        commit(MissionMutations.SET_IS_UPDATING_MISSION, false)
      })
      .catch(err => {
        console.error(err)
        commit(MissionMutations.SET_REQUEST_STATE_MISSION, err)
        commit(MissionMutations.SET_IS_UPDATING_MISSION, false)
      })
  },
}
