import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { ContractResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../../RootState'
import { IProfessionalContractTypeState } from './states'


export const getters: GetterTree<IProfessionalContractTypeState, RootState> = {
  professionalContractTypesList: (state): ContractResponseDto[] => state.professionalContractTypesList,
  professionalContractTypesListArchived: (state): ContractResponseDto[] => state.professionalContractTypesListArchived,
  professionalContractType     : (state): ContractResponseDto => state.professionalContractType,

  // COMMON
  isLoadingProfessionalContractTypesList   : (state): boolean => state.isLoadingProfessionalContractTypesList,
  isLoadingProfessionalContractTypesListArchived   : (state): boolean => state.isLoadingProfessionalContractTypesListArchived,
  isLoadingProfessionalContractType   : (state): boolean => state.isLoadingProfessionalContractType,
  isCreatingProfessionalContractType  : (state): boolean => state.isCreatingProfessionalContractType,
  isUpdatingProfessionalContractType  : (state): boolean => state.isUpdatingProfessionalContractType,
  isDeletingProfessionalContractType  : (state): boolean => state.isDeletingProfessionalContractType,
  requestStateProfessionalContractType: (state): IRequestState => state.requestStateProfessionalContractType,
  isOpenSidebarProfessionalContractType : (state): boolean => state.isOpenSidebarProfessionalContractType,
  isArchivingProfessionalContractType : (state): boolean => state.isArchivingProfessionalContractType,
}