import { StepResponseDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "../../RootState";
import { StepMutations } from "./mutations";
import { IStepState } from "./states";

export const actions: ActionTree<IStepState, RootState> = {
  /**
   * It fetches the list of steps from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchStepsList({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(StepMutations.SET_IS_LOADING_STEPS_LIST, true);
      commit(StepMutations.SET_IS_LOADING_STEPS_LIST_ARCHIVED, false);
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Management/Step/GetSteps", {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          commit(StepMutations.SET_STEPS_LIST, res.data);
          commit(StepMutations.SET_REQUEST_STATE_STEP, res);
          commit(StepMutations.SET_IS_LOADING_STEPS_LIST, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StepMutations.SET_REQUEST_STATE_STEP, err);
          commit(StepMutations.SET_IS_LOADING_STEPS_LIST, false);
          reject(err);
        });
    });
  },

  async fetchCommonStepsList({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(StepMutations.SET_IS_LOADING_COMMON_STEPS_LIST, true);
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Management/Step/GetCommonSteps", {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          commit(StepMutations.SET_COMMON_STEPS_LIST, res.data);
          commit(StepMutations.SET_REQUEST_STATE_COMMON_STEP, res);
          commit(StepMutations.SET_IS_LOADING_COMMON_STEPS_LIST, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StepMutations.SET_REQUEST_STATE_COMMON_STEP, err);
          commit(StepMutations.SET_IS_LOADING_COMMON_STEPS_LIST, false);
          reject(err);
        });
    });
  },
  async fetchCommonStepsListArchived({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(StepMutations.SET_IS_LOADING_COMMON_STEPS_LIST_ARCHIVED, true);
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Management/Step/GetCommonStepsArchived", {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {          
          commit(StepMutations.SET_COMMON_STEPS_LIST_ARCHIVED, res.data);
          commit(StepMutations.SET_REQUEST_STATE_STEP, res);
          commit(StepMutations.SET_IS_LOADING_COMMON_STEPS_LIST_ARCHIVED, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StepMutations.SET_REQUEST_STATE_STEP, err);
          commit(StepMutations.SET_IS_LOADING_COMMON_STEPS_LIST_ARCHIVED, false);
          reject(err);
        });
    });
  },

  async fetchStepsListByInterventionId(
    { commit, rootGetters },
    interventionId: number
  ): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(StepMutations.SET_IS_LOADING_STEPS_LIST, true);
      commit(StepMutations.SET_IS_LOADING_STEPS_LIST_ARCHIVED, false);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/Step/GetStepsByInterventionId",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
              params: {
                interventionId: interventionId,
              },
            },
          }
        )
        .then((res) => {
          commit(StepMutations.SET_STEPS_LIST, res.data);
          commit(StepMutations.SET_REQUEST_STATE_STEP, res);
          commit(StepMutations.SET_IS_LOADING_STEPS_LIST, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StepMutations.SET_REQUEST_STATE_STEP, err);
          commit(StepMutations.SET_IS_LOADING_STEPS_LIST, false);
          reject(err);
        });
    });
  },

  async fetchStep({ commit, rootGetters }, stepId: string): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(StepMutations.SET_IS_LOADING_STEP, true);
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Management/Step/GetStepById", {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            stepTypeId: stepId,
          },
        })
        .then((res) => {
          commit(StepMutations.SET_STEP, res.data);
          commit(StepMutations.SET_REQUEST_STATE_STEP, res);
          commit(StepMutations.SET_IS_LOADING_STEP, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StepMutations.SET_REQUEST_STATE_STEP, err);
          commit(StepMutations.SET_IS_LOADING_STEP, false);
          reject(err);
        });
    });
  },

  /**
   * It fetches the step data from the API.
   * @param  - stepId: The stepId of the step you want to fetch.
   * @param {string} stepId - string
   */
  async fetchStepArchived(
    { commit, rootGetters },
    stepId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(StepMutations.SET_IS_LOADING_STEP, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/Step/GetStepArchivedById",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              stepId: stepId,
            },
          }
        )
        .then((res) => {
          commit(StepMutations.SET_STEP, res.data);
          commit(StepMutations.SET_REQUEST_STATE_STEP, res);
          commit(StepMutations.SET_IS_LOADING_STEP, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StepMutations.SET_REQUEST_STATE_STEP, err);
          commit(StepMutations.SET_IS_LOADING_STEP, false);
          reject(err);
        });
    });
  },

  /**
   * It creates a new step.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {StepResponseDto} step - StepResponseDto
   */
  async createStep(
    { commit, rootGetters },
    step: StepResponseDto
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(StepMutations.SET_IS_CREATING_STEP, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Management/Step/CreateStep",
          step,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
          }
        )
        .then((res) => {
          rootGetters.commonStepsList.unshift(res.data.data);
          commit(StepMutations.SET_STEPS_LIST, rootGetters.stepsList);
          commit(StepMutations.SET_REQUEST_STATE_STEP, res);
          commit(StepMutations.SET_IS_CREATING_STEP, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit(StepMutations.SET_REQUEST_STATE_STEP, err);
          commit(StepMutations.SET_IS_CREATING_STEP, false);
          reject(err);
        });
    });
  },
  /**
   * Update an step
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {StepResponseDto} step - StepResponseDto
   */
  async updateStep({ commit, rootGetters }, step: StepResponseDto) {
    return await new Promise((resolve, reject) => {
    commit(StepMutations.SET_IS_UPDATING_STEP, true);
    axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/Step/UpdateStep`,
        { ...step},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        rootGetters.stepsList.splice(
          rootGetters.stepsList.findIndex((elem) => elem.id == step.id),
          1,
          step
        );
        commit(StepMutations.SET_STEPS_LIST, rootGetters.stepsList);
        commit(StepMutations.SET_REQUEST_STATE_STEP, res);
        commit(StepMutations.SET_IS_UPDATING_STEP, false);
        resolve(res.data.data)
      })
      .catch((err) => {
        commit(StepMutations.SET_REQUEST_STATE_STEP, err);
        commit(StepMutations.SET_IS_UPDATING_STEP, false);
        reject(err)
      });
    });
  },
  async updateStepIndex ({commit, rootGetters}, steps) {
    return await new Promise ((resolve, reject) => {
      commit(StepMutations.SET_IS_UPDATING_STEP)
      axios 
        .put(
          `${process.env.VUE_APP_API_ORISIS}Management/Step/UpdateStepIndex`,
          steps,
          {
            headers: {
              Authorization : `Bearer ${rootGetters.userToken}`
            }
          }
        )
        .then(res => {
          commit(StepMutations.SET_IS_UPDATING_STEP, false)
          resolve(res.data.data)
        })
        .catch(err =>{
          commit(StepMutations.SET_REQUEST_STATE_STEP, err)
          commit(StepMutations.SET_IS_UPDATING_STEP, false)
          reject(err)
        })
    })
  },
  async updateMissionStep({ commit, rootGetters }, {stepId, missions}) {
    commit(StepMutations.SET_IS_UPDATING_MISSION_STEP, true);
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/Step/UpdateMissionStep`,
        missions,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            stepId: stepId,
          }
        }
      )
      .then((res) => {
        const step = rootGetters.commonStepsList.find(e => e.id === stepId)
        step.missions.splice(0, step.missions.length)
        const newMissions = rootGetters.missionsList.filter(elem => missions.includes(elem.id))
        newMissions.forEach(elem => step.missions.push({stepId: stepId, mission: elem}))
        rootGetters.commonStepsList.splice(rootGetters.commonStepsList.findIndex(elem => elem.id === step.id), 1, step)
        const result = rootGetters.commonStepsList
        commit(StepMutations.SET_COMMON_STEPS_LIST, result);
        commit(StepMutations.SET_STEPS_LIST, result);
        commit(StepMutations.SET_REQUEST_STATE_STEP, res);
        commit(StepMutations.SET_IS_UPDATING_MISSION_STEP, false);
      })
      .catch((err) => {
        commit(StepMutations.SET_REQUEST_STATE_STEP, err);
        commit(StepMutations.SET_IS_UPDATING_MISSION_STEP, false);
      });
  },
  async updateMissionStepIntervention({ commit, rootGetters }, {stepId, step}) {
    commit(StepMutations.SET_IS_UPDATING_MISSION_STEP_INTERVENTION, true);
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/Step/UpdateMissionStepIntervention`,
        step,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            stepId: stepId,
          }
        }
      )
      .then((res) => {
        commit(StepMutations.SET_STEPS_LIST, rootGetters.stepsList);
        commit(StepMutations.SET_REQUEST_STATE_STEP, res);
        commit(StepMutations.SET_IS_UPDATING_MISSION_STEP, false);
      })
      .catch((err) => {
        commit(StepMutations.SET_REQUEST_STATE_STEP, err);
        commit(StepMutations.SET_IS_UPDATING_MISSION_STEP, false);
      });
  },

  /**
   * It deletes an step from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} stepId - string
   */
  async deleteStep({ commit, rootGetters }, stepId: string) {
    commit(StepMutations.SET_IS_DELETING_STEP, true);
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Management/Step/DeleteStep`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`,
        },
        params: {
          stepTypeId: stepId,
        },
      })
      .then((res) => {
        rootGetters.commonStepsListArchived.splice(
          rootGetters.commonStepsListArchived.findIndex((elem) => elem.id == stepId),
          1
        );
        commit(StepMutations.SET_STEPS_LIST, rootGetters.commonStepsList);
        commit(StepMutations.SET_REQUEST_STATE_STEP, res);
        commit(StepMutations.SET_IS_DELETING_STEP, false);
      })
      .catch((err) => {
        commit(StepMutations.SET_REQUEST_STATE_STEP, err);
        commit(StepMutations.SET_IS_DELETING_STEP, false);
      });
  },

  async archiveStep({ commit, rootGetters }, stepId: string): Promise<void> {
    commit(StepMutations.SET_IS_ARCHIVING_STEP, true);
    axios
      .delete(process.env.VUE_APP_API_ORISIS + "Management/Step/Archive", {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`,
        },
        data: [stepId],
      })
      .then((res) => {
        rootGetters.commonStepsListArchived.unshift(
          rootGetters.commonStepsList.find((elem) => elem.id == stepId)
        );
        rootGetters.commonStepsList.splice(
          rootGetters.commonStepsList.findIndex((elem) => elem.id == stepId),
          1
        );
        commit(StepMutations.SET_STEPS_LIST, rootGetters.stepsList);
        commit(StepMutations.SET_REQUEST_STATE_STEP, res);
        commit(StepMutations.SET_IS_ARCHIVING_STEP, false);
      })
      .catch((err) => {
        console.error(err);
        commit(StepMutations.SET_REQUEST_STATE_STEP, err);
        commit(StepMutations.SET_IS_ARCHIVING_STEP, false);
      });
  },

  async restoreStep({ commit, rootGetters }, stepId: string): Promise<void> {
    commit(StepMutations.SET_IS_UPDATING_STEP, true);
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + "Management/Step/Restore",
        [stepId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        rootGetters.commonStepsList.unshift(
          rootGetters.commonStepsListArchived.find((elem) => elem.id == stepId)
        );
        rootGetters.commonStepsListArchived.splice(
          rootGetters.commonStepsListArchived.findIndex((elem) => elem.id == stepId),
          1
        );
        commit(StepMutations.SET_REQUEST_STATE_STEP, res);
        commit(StepMutations.SET_IS_UPDATING_STEP, false);
      })
      .catch((err) => {
        console.error(err);
        commit(StepMutations.SET_REQUEST_STATE_STEP, err);
        commit(StepMutations.SET_IS_UPDATING_STEP, false);
      });
  },
};
