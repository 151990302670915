import { PoolAccessTypeResponseDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../../RootState'
import { PoolAccessTypeMutations } from './mutations'
import { IPoolAccessTypeState } from './states'

export const actions: ActionTree<IPoolAccessTypeState, RootState> = {
  /**
   * It fetches the list of poolAccessTypes from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchPoolAccessTypesList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPES_LIST, true)
      commit(PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPES_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/PoolAccessType/GetPoolAccessTypes',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(PoolAccessTypeMutations.SET_POOL_ACCESS_TYPES_LIST, res.data)
          commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, res)
          commit(PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPES_LIST, false)
          resolve(res.data)

        })
        .catch(err => {
          console.error(err)
          commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, err)
          commit(PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPES_LIST, false)
          reject(err)
        })
    })
  },

  async fetchPoolAccessTypesListArchived ({ commit, rootGetters }): Promise<void> {
    commit(PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPES_LIST_ARCHIVED, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Management/PoolAccessType/GetPoolAccessTypesArchived',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
        }
      )
      .then(res => {
        commit(PoolAccessTypeMutations.SET_POOL_ACCESS_TYPES_LIST_ARCHIVED, res.data)
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, res)
        commit(PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPES_LIST_ARCHIVED, false)
      })
      .catch(err => {
        console.error(err)
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, err)
        commit(PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPES_LIST_ARCHIVED, false)
      })
  },

  async fetchPoolAccessType (
    { commit, rootGetters },
    poolAccessTypeId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPE, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Management/PoolAccessType/GetPoolAccessTypeById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                poolAccessTypeId: poolAccessTypeId
              }
            }
          )
          .then(res => {
            commit(PoolAccessTypeMutations.SET_POOL_ACCESS_TYPE, res.data)
            commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, res)
            commit(PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPE, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, err)
            commit(PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPE, false)
            reject(err)
          })
    })
  },
  /**
   * It creates a new poolAccessType.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {PoolAccessTypeResponseDto} poolAccessType - PoolAccessTypeResponseDto
   */
   async createPoolAccessType ( 
    { commit, rootGetters },
    poolAccessType: PoolAccessTypeResponseDto
  ): Promise<void> {

    return await new Promise((resolve, reject) => {
    commit(PoolAccessTypeMutations.SET_IS_CREATING_POOL_ACCESS_TYPE, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Management/PoolAccessType/CreatePoolAccessType',
        poolAccessType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.poolAccessTypesList.unshift(res.data.data)

        commit(PoolAccessTypeMutations.SET_POOL_ACCESS_TYPES_LIST, rootGetters.poolAccessTypesList)
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, res)
        commit(PoolAccessTypeMutations.SET_IS_CREATING_POOL_ACCESS_TYPE, false)
        resolve(res.data.data)
      })
      .catch(err => {
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, err)
        commit(PoolAccessTypeMutations.SET_IS_CREATING_POOL_ACCESS_TYPE, false)
        reject(err)
      })
    })
  },
  /**
   * Update an poolAccessType
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {PoolAccessTypeResponseDto} poolAccessType - PoolAccessTypeResponseDto
   */
  async updatePoolAccessType ({ commit, rootGetters }, poolAccessType: PoolAccessTypeResponseDto) {
    commit(PoolAccessTypeMutations.SET_IS_UPDATING_POOL_ACCESS_TYPE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/PoolAccessType/UpdatePoolAccessType`,
        {...poolAccessType},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.poolAccessTypesList.splice(
          rootGetters.poolAccessTypesList.findIndex(elem => elem.id == poolAccessType.id),
          1,
          poolAccessType
        )
        commit(PoolAccessTypeMutations.SET_POOL_ACCESS_TYPES_LIST, rootGetters.poolAccessTypesList)
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, res)
        commit(PoolAccessTypeMutations.SET_IS_UPDATING_POOL_ACCESS_TYPE, false)
      })
      .catch(err => {
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, err)
        commit(PoolAccessTypeMutations.SET_IS_UPDATING_POOL_ACCESS_TYPE, false)
      })
  },

  /**
   * It deletes an poolAccessType from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} poolAccessTypeId - string
   */
  async deletePoolAccessType ({ commit, rootGetters }, poolAccessTypeId: string) {
    commit(PoolAccessTypeMutations.SET_IS_DELETING_POOL_ACCESS_TYPE, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Management/PoolAccessType/DeletePoolAccessType`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          poolAccessTypeId: poolAccessTypeId
        }
      })
      .then(res => {
        rootGetters.poolAccessTypesListArchived.splice(
          rootGetters.poolAccessTypesListArchived.findIndex(elem => elem.id == poolAccessTypeId),
          1
        )
        commit(PoolAccessTypeMutations.SET_POOL_ACCESS_TYPES_LIST, rootGetters.poolAccessTypesList)
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, res)
        commit(PoolAccessTypeMutations.SET_IS_DELETING_POOL_ACCESS_TYPE, false)
      })
      .catch(err => {
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, err)
        commit(PoolAccessTypeMutations.SET_IS_DELETING_POOL_ACCESS_TYPE, false)
      })
  },

  async archivePoolAccessType (
    { commit, rootGetters },
    poolAccessTypeId: string
  ): Promise<void> {
    commit(PoolAccessTypeMutations.SET_IS_ARCHIVING_POOL_ACCESS_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Management/PoolAccessType/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[poolAccessTypeId]
        }
      )
      .then(res => {
        rootGetters.poolAccessTypesListArchived.unshift(rootGetters.poolAccessTypesList.find(elem => elem.id == poolAccessTypeId ))
        rootGetters.poolAccessTypesList.splice(
          rootGetters.poolAccessTypesList.findIndex(
            elem => elem.id == poolAccessTypeId
          ),
          1
        )
        commit(
          PoolAccessTypeMutations.SET_POOL_ACCESS_TYPES_LIST,
          rootGetters.poolAccessTypesList
        )
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, res)
        commit(PoolAccessTypeMutations.SET_IS_ARCHIVING_POOL_ACCESS_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, err)
        commit(PoolAccessTypeMutations.SET_IS_ARCHIVING_POOL_ACCESS_TYPE, false)
      })
   },

   async restorePoolAccessType (
    { commit, rootGetters },
    poolAccessTypeId: string
  ): Promise<void> {
    commit(PoolAccessTypeMutations.SET_IS_UPDATING_POOL_ACCESS_TYPE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Management/PoolAccessType/Restore',
        [poolAccessTypeId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.poolAccessTypesList.unshift(rootGetters.poolAccessTypesListArchived.find(elem => elem.id == poolAccessTypeId ))
        rootGetters.poolAccessTypesListArchived.splice(
          rootGetters.poolAccessTypesListArchived.findIndex(
            elem => elem.id == poolAccessTypeId
          ),
          1
        )
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, res)
        commit(PoolAccessTypeMutations.SET_IS_UPDATING_POOL_ACCESS_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE, err)
        commit(PoolAccessTypeMutations.SET_IS_UPDATING_POOL_ACCESS_TYPE, false)
      })
   }
}
