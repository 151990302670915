import { GetterTree } from "vuex";
import RootState from "../../RootState";
import { IInterventionStatisticsState } from "./states";

export const getters: GetterTree<IInterventionStatisticsState, RootState> = {
  statsInterventions: (state): any => state.statsInterventions,
  // COMMON
  isLoadingStatsInterventions: (state): boolean =>
    state.isLoadingStatsInterventions,
};
