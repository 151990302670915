import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { AccessMethodTypeResponseDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "../../../RootState";
import { IAccessMethodTypeState } from "./states";

export const getters: GetterTree<IAccessMethodTypeState, RootState> = {
  accessMethodTypesList: (state): AccessMethodTypeResponseDto[] =>
    state.accessMethodTypesList,
  accessMethodTypesListArchived: (state): AccessMethodTypeResponseDto[] =>
    state.accessMethodTypesListArchived,
  accessMethodType: (state): AccessMethodTypeResponseDto =>
    state.accessMethodType,

  // COMMON
  isLoadingAccessMethodTypesList: (state): boolean =>
    state.isLoadingAccessMethodTypesList,
  isLoadingAccessMethodTypesListArchived: (state): boolean =>
    state.isLoadingAccessMethodTypesListArchived,
  isLoadingAccessMethodType: (state): boolean =>
    state.isLoadingAccessMethodType,
  isCreatingAccessMethodType: (state): boolean =>
    state.isCreatingAccessMethodType,
  isUpdatingAccessMethodType: (state): boolean =>
    state.isUpdatingAccessMethodType,
  isDeletingAccessMethodType: (state): boolean =>
    state.isDeletingAccessMethodType,
  requestStateAccessMethodType: (state): IRequestState =>
    state.requestStateAccessMethodType,
  isOpenSidebarAccessMethodType: (state): boolean =>
    state.isOpenSidebarAccessMethodType,
  isArchivingAccessMethodType: (state): boolean =>
    state.isArchivingAccessMethodType,
};
