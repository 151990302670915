import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ProductUsedResponseDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import { IProductUsedState } from "./states";

export const getters: GetterTree<IProductUsedState, RootState> = {
  productUsedList: (state): ProductUsedResponseDto[] => state.productUsedList,
  productUsed: (state): ProductUsedResponseDto => state.productUsed,

  // COMMON
  isLoadingProductUsedList: (state): boolean => state.isLoadingProductUsedList,
  isLoadingProductUsed: (state): boolean => state.isLoadingProductUsed,
  isCreatingProductUsed: (state): boolean => state.isCreatingProductUsed,
  isUpdatingProductUsed: (state): boolean => state.isUpdatingProductUsed,
  isDeletingProductUsed: (state): boolean => state.isDeletingProductUsed,
  requestStateProductUsed: (state): IRequestState =>
    state.requestStateProductUsed,
  isOpenSidebarProductUsed: (state): boolean => state.isOpenSidebarProductUsed,
};
