import { IProductUsedState } from "./states";
import { Module } from "vuex";
import { ProductUsedResponseDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/RootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IProductUsedState = {
  productUsedList: Array<ProductUsedResponseDto>(),
  productUsed: <ProductUsedResponseDto>{},
  isLoadingProductUsedList: false,
  isLoadingProductUsed: false,
  isCreatingProductUsed: false,
  isUpdatingProductUsed: false,
  isDeletingProductUsed: false,
  requestStateProductUsed: <IRequestState>{},
  isOpenSidebarProductUsed: false,
};

export const ProductUsedModule: Module<IProductUsedState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
