import { PoolResponseDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "../../RootState";
import { PoolMutations } from "./mutations";
import { IPoolState } from "./states";

export const actions: ActionTree<IPoolState, RootState> = {
  /**
   * It fetches the list of pools from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchPoolsList({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(PoolMutations.SET_IS_LOADING_POOLS_LIST, true);
      commit(PoolMutations.SET_IS_LOADING_POOLS_LIST_ARCHIVED, false);
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Management/Pool/GetPools", {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          commit(PoolMutations.SET_POOLS_LIST, res.data);
          commit(PoolMutations.SET_REQUEST_STATE_POOL, res);
          commit(PoolMutations.SET_IS_LOADING_POOLS_LIST, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PoolMutations.SET_REQUEST_STATE_POOL, err);
          commit(PoolMutations.SET_IS_LOADING_POOLS_LIST, false);
        });
    });
  },

  async fetchPoolsListArchived({ commit, rootGetters }): Promise<void> {
    commit(PoolMutations.SET_IS_LOADING_POOLS_LIST_ARCHIVED, true);
    axios
      .get(
        process.env.VUE_APP_API_ORISIS + "Management/Pool/GetPoolsArchived",
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        commit(PoolMutations.SET_POOLS_LIST_ARCHIVED, res.data);
        commit(PoolMutations.SET_REQUEST_STATE_POOL, res);
        commit(PoolMutations.SET_IS_LOADING_POOLS_LIST_ARCHIVED, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PoolMutations.SET_REQUEST_STATE_POOL, err);
        commit(PoolMutations.SET_IS_LOADING_POOLS_LIST_ARCHIVED, false);
      });
  },

  async fetchPool({ commit, rootGetters }, poolId: string): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(PoolMutations.SET_IS_LOADING_POOL, true);
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Management/Pool/GetPoolById", {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            poolId: poolId,
          },
        })
        .then((res) => {
          commit(PoolMutations.SET_POOL, res.data);
          commit(PoolMutations.SET_REQUEST_STATE_POOL, res);
          commit(PoolMutations.SET_IS_LOADING_POOL, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PoolMutations.SET_REQUEST_STATE_POOL, err);
          commit(PoolMutations.SET_IS_LOADING_POOL, false);
          reject(err);
        });
    });
  },

  /**
   * It fetches the pool data from the API.
   * @param  - poolId: The poolId of the pool you want to fetch.
   * @param {string} poolId - string
   */
  async fetchPoolArchived(
    { commit, rootGetters },
    poolId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(PoolMutations.SET_IS_LOADING_POOL, true);

      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/Pool/GetPoolArchivedById",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              poolId: poolId,
            },
          }
        )
        .then((res) => {
          commit(PoolMutations.SET_POOL, res.data);
          commit(PoolMutations.SET_REQUEST_STATE_POOL, res);
          commit(PoolMutations.SET_IS_LOADING_POOL, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PoolMutations.SET_REQUEST_STATE_POOL, err);
          commit(PoolMutations.SET_IS_LOADING_POOL, false);
          reject(err);
        });
    });
  },

  /**
   * It creates a new pool.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {PoolResponseDto} pool - PoolResponseDto
   */
  async createPool(
    { commit, rootGetters },
    pool: PoolResponseDto
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(PoolMutations.SET_IS_CREATING_POOL, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Management/Pool/CreatePool",
          pool,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
          }
        )
        .then((res) => {
          rootGetters.poolsList.unshift(res.data.data);

          commit(PoolMutations.SET_POOLS_LIST, rootGetters.poolsList);
          commit(PoolMutations.SET_REQUEST_STATE_POOL, res);
          commit(PoolMutations.SET_IS_CREATING_POOL, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit(PoolMutations.SET_REQUEST_STATE_POOL, err);
          commit(PoolMutations.SET_IS_CREATING_POOL, false);
          reject(err);
        });
    });
  },
  /**
   * Update an pool
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {PoolResponseDto} pool - PoolResponseDto
   */
  async updatePool({ commit, rootGetters }, pool: PoolResponseDto) {
    commit(PoolMutations.SET_IS_UPDATING_POOL, true);
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/Pool/UpdatePool`,
        { ...pool },
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        rootGetters.poolsList.splice(
          rootGetters.poolsList.findIndex((elem) => elem.id == pool.id),
          1,
          pool
        );
        commit(PoolMutations.SET_POOLS_LIST, rootGetters.poolsList);
        commit(PoolMutations.SET_REQUEST_STATE_POOL, res);
        commit(PoolMutations.SET_IS_UPDATING_POOL, false);
      })
      .catch((err) => {
        commit(PoolMutations.SET_REQUEST_STATE_POOL, err);
        commit(PoolMutations.SET_IS_UPDATING_POOL, false);
      });
  },
  uploadPoolFile(
    { rootGetters },
    {poolId, label, file}
  ) {
    return new Promise((resolve, reject) => {
    axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/Pool/UploadFile`,
        file,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            poolId: poolId,
            label: label
          }
        }
      )
      .then((res) => {        
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      });
    })
  },

  /**
   * It deletes an pool from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} poolId - string
   */
  async deletePool({ commit, rootGetters }, poolId: string) {
    commit(PoolMutations.SET_IS_DELETING_POOL, true);
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Management/Pool/DeletePool`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`,
        },
        params: {
          poolId: poolId,
        },
      })
      .then((res) => {
        rootGetters.poolsListArchived.splice(
          rootGetters.poolsListArchived.findIndex((elem) => elem.id == poolId),
          1
        );
        commit(PoolMutations.SET_POOLS_LIST, rootGetters.poolsList);
        commit(PoolMutations.SET_REQUEST_STATE_POOL, res);
        commit(PoolMutations.SET_IS_DELETING_POOL, false);
      })
      .catch((err) => {
        commit(PoolMutations.SET_REQUEST_STATE_POOL, err);
        commit(PoolMutations.SET_IS_DELETING_POOL, false);
      });
  },

  async archivePool({ commit, rootGetters }, poolId: string): Promise<void> {
    commit(PoolMutations.SET_IS_ARCHIVING_POOL, true);
    axios
      .delete(process.env.VUE_APP_API_ORISIS + "Management/Pool/Archive", {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`,
        },
        data: [poolId],
      })
      .then((res) => {
        rootGetters.poolsListArchived.unshift(
          rootGetters.poolsList.find((elem) => elem.id == poolId)
        );
        rootGetters.poolsList.splice(
          rootGetters.poolsList.findIndex((elem) => elem.id == poolId),
          1
        );
        commit(PoolMutations.SET_POOLS_LIST, rootGetters.poolsList);
        commit(PoolMutations.SET_REQUEST_STATE_POOL, res);
        commit(PoolMutations.SET_IS_ARCHIVING_POOL, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PoolMutations.SET_REQUEST_STATE_POOL, err);
        commit(PoolMutations.SET_IS_ARCHIVING_POOL, false);
      });
  },

  async restorePool({ commit, rootGetters }, poolId: string): Promise<void> {
    commit(PoolMutations.SET_IS_UPDATING_POOL, true);
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + "Management/Pool/Restore",
        [poolId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        rootGetters.poolsList.unshift(
          rootGetters.poolsListArchived.find((elem) => elem.id == poolId)
        );
        rootGetters.poolsListArchived.splice(
          rootGetters.poolsListArchived.findIndex((elem) => elem.id == poolId),
          1
        );
        commit(PoolMutations.SET_REQUEST_STATE_POOL, res);
        commit(PoolMutations.SET_IS_UPDATING_POOL, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PoolMutations.SET_REQUEST_STATE_POOL, err);
        commit(PoolMutations.SET_IS_UPDATING_POOL, false);
      });
  },
};
