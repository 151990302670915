import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { ContractTypeResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../../RootState'
import { IContractMaintenanceTypeState } from './states'


export const getters: GetterTree<IContractMaintenanceTypeState, RootState> = {
  contractMaintenanceTypesList: (state): ContractTypeResponseDto[] => state.contractMaintenanceTypesList,
  contractMaintenanceTypesListArchived: (state): ContractTypeResponseDto[] => state.contractMaintenanceTypesListArchived,
  contractMaintenanceType     : (state): ContractTypeResponseDto => state.contractMaintenanceType,

  // COMMON
  isLoadingContractMaintenanceTypesList   : (state): boolean => state.isLoadingContractMaintenanceTypesList,
  isLoadingContractMaintenanceTypesListArchived   : (state): boolean => state.isLoadingContractMaintenanceTypesListArchived,
  isLoadingContractMaintenanceType   : (state): boolean => state.isLoadingContractMaintenanceType,
  isCreatingContractMaintenanceType  : (state): boolean => state.isCreatingContractMaintenanceType,
  isUpdatingContractMaintenanceType  : (state): boolean => state.isUpdatingContractMaintenanceType,
  isDeletingContractMaintenanceType  : (state): boolean => state.isDeletingContractMaintenanceType,
  requestStateContractMaintenanceType: (state): IRequestState => state.requestStateContractMaintenanceType,
  isOpenSidebarContractMaintenanceType : (state): boolean => state.isOpenSidebarContractMaintenanceType,
  isArchivingContractMaintenanceType : (state): boolean => state.isArchivingContractMaintenanceType,
}