import { InterventionResponseDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "../../RootState";
import { InterventionMutations } from "./mutations";
import { IInterventionState } from "./states";

export const actions: ActionTree<IInterventionState, RootState> = {
  /**
   * It fetches the list of interventions from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchInterventionsList({ commit, rootGetters }, {startDate = null, endDate = null}): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST, true);
      commit(
        InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST_ARCHIVED,
        false
      );
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/Intervention/GetInterventions',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              startTo: startDate,
              endTo: endDate,
            },
          }
        )
        .then((res) => {
          commit(InterventionMutations.SET_INTERVENTIONS_LIST, res.data.map(elem => {return {...elem, dateTime: elem.dateTime+'Z'}}));
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          commit(
            InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST,
            false
          );
          resolve(res.data.map(elem => {return {...elem, dateTime: elem.dateTime+'Z'}}));
        })
        .catch((err) => {
          console.error(err);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          commit(
            InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST,
            false
          );
          reject(err)
        });
    });
  },

  async fetchInterventionsListOnTimeSpan(
    { commit, rootGetters },
    { startTo, endTo }
  ): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST, true);
      commit(
        InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST_ARCHIVED,
        false
      );
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/Intervention/GetInterventionsOnTimeSpan",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              startTo: startTo,
              endTo: endTo,
            },
          }
        )
        .then((res) => {
          commit(InterventionMutations.SET_INTERVENTIONS_LIST, res.data.map(elem => {return {...elem, dateTime: elem.dateTime+'Z'}}));
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          commit(
            InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST,
            false
          );
          resolve(res.data.map(elem => {return {...elem, dateTime: elem.dateTime+'Z'}}));
        })
        .catch((err) => {
          console.error(err);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          commit(
            InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST,
            false
          );
        });
    });
  },

  async fetchIntervention(
    { commit, rootGetters },
    interventionId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/Intervention/GetInterventionById",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              interventionId: interventionId,
            },
          }
        )
        .then((res) => {
          const result = res.data
          result.dateTime = result.dateTime+'Z'
          for (let i = 0; i < result.steps.length; i++) {
            const step = result.steps[i];
            step.missions = result.missions.filter(elem => elem.stepId == step.step.id)
          }
          commit(InterventionMutations.SET_INTERVENTION, result);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, false);
          resolve(
            res.data,
);
        })
        .catch((err) => {
          console.error(err);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, false);
          reject(err)
        });
    });
  },

  /**
   * It fetches the intervention data from the API.
   * @param  - interventionId: The interventionId of the intervention you want to fetch.
   * @param {string} interventionId - string
   */
  async fetchInterventionArchived(
    { commit, rootGetters },
    interventionId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/Intervention/GetInterventionArchivedById",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              interventionId: interventionId,
            },
          }
        )
        .then((res) => {
          commit(InterventionMutations.SET_INTERVENTION, {...res.data, dateTime: res.data.dateTime+'Z'});
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, false);
        });
    });
  },
  async fetchInterventionsArchivedList(
    { commit, rootGetters },
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/Intervention/GetInterventionsArchived",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
          }
        )
        .then((res) => {
          commit(InterventionMutations.SET_INTERVENTIONS_LIST_ARCHIVED, res.data.map(elem => {return {...elem, dateTime: elem.dateTime+'Z'}}));
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, false);
          resolve(res.data.map(elem => {return {...elem, dateTime: elem.dateTime+'Z'}}));
        })
        .catch((err) => {
          console.error(err);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, false);
        });
    });
  },

  /**
   * It creates a new intervention.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {InterventionResponseDto} intervention - InterventionResponseDto
   */
  async createIntervention(
    { commit, rootGetters },
    intervention: InterventionResponseDto
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(InterventionMutations.SET_IS_CREATING_INTERVENTION, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Management/Intervention/CreateIntervention",
          intervention,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
          }
        )
        .then((res) => {
          rootGetters.interventionsList.unshift(res.data.data);
          commit(
            InterventionMutations.SET_INTERVENTIONS_LIST,
            rootGetters.interventionsList
          );
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          commit(InterventionMutations.SET_IS_CREATING_INTERVENTION, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          commit(InterventionMutations.SET_IS_CREATING_INTERVENTION, false);
          reject(err);
        });
    });
  },
  async createInterventionByRecurence(
    { commit, rootGetters },
    intervention: InterventionResponseDto
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(InterventionMutations.SET_IS_CREATING_INTERVENTION, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Management/Intervention/CreateInterventionByRecurence",
          intervention,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
          }
        )
        .then((res) => {
          rootGetters.interventionsList.unshift(...res.data);
          commit(
            InterventionMutations.SET_INTERVENTIONS_LIST,
            rootGetters.interventionsList
          );
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          commit(InterventionMutations.SET_IS_CREATING_INTERVENTION, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          commit(InterventionMutations.SET_IS_CREATING_INTERVENTION, false);
          reject(err);
        });
    });
  },
  /**
   * Update an intervention
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {InterventionResponseDto} intervention - InterventionResponseDto
   */
  async updateIntervention(
    { commit, rootGetters },
    intervention: InterventionResponseDto
  ) {
    return await new Promise((resolve, reject) => {
    commit(InterventionMutations.SET_IS_UPDATING_INTERVENTION, true);
    axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/Intervention/UpdateIntervention`,
        { ...intervention},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        rootGetters.interventionsList.splice(
          rootGetters.interventionsList.findIndex(
            (elem) => elem.id == intervention.id
          ),
          1,
          res.data.data
        );
        commit(
          InterventionMutations.SET_INTERVENTIONS_LIST,
          rootGetters.interventionsList
        );
        commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
        commit(InterventionMutations.SET_IS_UPDATING_INTERVENTION, false);
        resolve(res.data.data)
      })
      .catch((err) => {
        commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
        commit(InterventionMutations.SET_IS_UPDATING_INTERVENTION, false);
        reject(err)
      });
    })
  },
  uploadInterventionFile(
    { rootGetters },
    {interventionId, label, file}
  ) {
    return new Promise((resolve, reject) => {
    axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/Intervention/UploadFile`,
        file,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            interventionId: interventionId,
            label: label
          }
        }
      )
      .then((res) => {        
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      });
    })
  },
  async updateStepIntervention(
    { commit, rootGetters },
    {interventionId, steps}
  ) {
    commit(InterventionMutations.SET_IS_UPDATING_STEP_INTERVENTION, true);
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/Intervention/UpdateStepIntervention`,
        steps,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            interventionId: interventionId
          }
        }
      )
      .then((res) => {
        commit(
          InterventionMutations.SET_INTERVENTIONS_LIST,
          rootGetters.interventionsList
        );
        commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
        commit(InterventionMutations.SET_IS_UPDATING_INTERVENTION, false);
      })
      .catch((err) => {
        commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
        commit(InterventionMutations.SET_IS_UPDATING_INTERVENTION, false);
      });
  },
  

  /**
   * It deletes an intervention from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} interventionId - string
   */
  async deleteIntervention({ commit, rootGetters }, interventionId: string) {
    commit(InterventionMutations.SET_IS_DELETING_INTERVENTION, true);
    await axios
      .delete(
        `${process.env.VUE_APP_API_ORISIS}Management/Intervention/DeleteIntervention`,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            interventionId: interventionId,
          },
        }
      )
      .then((res) => {
        rootGetters.interventionsListArchived.splice(
          rootGetters.interventionsListArchived.findIndex(
            (elem) => elem.id == interventionId
          ),
          1
        );
        commit(
          InterventionMutations.SET_INTERVENTIONS_LIST,
          rootGetters.interventionsList
        );
        commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
        commit(InterventionMutations.SET_IS_DELETING_INTERVENTION, false);
      })
      .catch((err) => {
        commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
        commit(InterventionMutations.SET_IS_DELETING_INTERVENTION, false);
      });
  },

  async archiveIntervention(
    { commit, rootGetters },
    interventionId: string
  ): Promise<void> {
    commit(InterventionMutations.SET_IS_ARCHIVING_INTERVENTION, true);
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + "Management/Intervention/Archive",
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          data: [interventionId],
        }
      )
      .then((res) => {
        rootGetters.interventionsListArchived.unshift(
          rootGetters.interventionsList.find(
            (elem) => elem.id == interventionId
          )
        );
        rootGetters.interventionsList.splice(
          rootGetters.interventionsList.findIndex(
            (elem) => elem.id == interventionId
          ),
          1
        );
        commit(
          InterventionMutations.SET_INTERVENTIONS_LIST,
          rootGetters.interventionsList
        );
        commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
        commit(InterventionMutations.SET_IS_ARCHIVING_INTERVENTION, false);
      })
      .catch((err) => {
        console.error(err);
        commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
        commit(InterventionMutations.SET_IS_ARCHIVING_INTERVENTION, false);
      });
  },

  async restoreIntervention(
    { commit, rootGetters },
    interventionId: string
  ): Promise<void> {
    commit(InterventionMutations.SET_IS_UPDATING_INTERVENTION, true);
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + "Management/Intervention/Restore",
        [interventionId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        rootGetters.interventionsList.unshift(
          rootGetters.interventionsListArchived.find(
            (elem) => elem.id == interventionId
          )
        );
        rootGetters.interventionsListArchived.splice(
          rootGetters.interventionsListArchived.findIndex(
            (elem) => elem.id == interventionId
          ),
          1
        );
        commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
        commit(InterventionMutations.SET_IS_UPDATING_INTERVENTION, false);
      })
      .catch((err) => {
        console.error(err);
        commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
        commit(InterventionMutations.SET_IS_UPDATING_INTERVENTION, false);
      });
  },
};
