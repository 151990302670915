import { IDeviceState } from "./states";
import { Module } from "vuex";
import { DeviceResponseDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/RootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IDeviceState = {
  devicesList: Array<DeviceResponseDto>(),
  devicesListByPoolId: Array<DeviceResponseDto>(),
  devicesListArchived: Array<DeviceResponseDto>(),
  device: <DeviceResponseDto>{},
  isLoadingDevicesListByPoolId: false,
  isLoadingDevicesList: false,
  isLoadingDevicesListArchived: false,
  isLoadingDevice: false,
  isCreatingDevice: false,
  isUpdatingDevice: false,
  isDeletingDevice: false,
  requestStateDevice: <IRequestState>{},
  isOpenSidebarDevice: false,
  isArchivingDevice: false,
};

export const DeviceModule: Module<IDeviceState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
