import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { StepResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { IStepState } from './states'


export const getters: GetterTree<IStepState, RootState> = {
  stepsList: (state): StepResponseDto[] => state.stepsList,
  commonStepsList: (state): StepResponseDto[] => state.commonStepsList,
  stepsListArchived: (state): StepResponseDto[] => state.stepsListArchived,
  commonStepsListArchived: (state): StepResponseDto[] => state.commonStepsListArchived,
  step     : (state): StepResponseDto => state.step,

  // COMMON
  isLoadingStepsList   : (state): boolean => state.isLoadingStepsList,
  isLoadingCommonStepsList   : (state): boolean => state.isLoadingCommonStepsList,
  isLoadingStepsListArchived   : (state): boolean => state.isLoadingStepsListArchived,
  isLoadingCommonStepsListArchived   : (state): boolean => state.isLoadingCommonStepsListArchived,
  isLoadingStep   : (state): boolean => state.isLoadingStep,
  isCreatingStep  : (state): boolean => state.isCreatingStep,
  isUpdatingStep  : (state): boolean => state.isUpdatingStep,
  isUpdatingMissingStep  : (state): boolean => state.isUpdatingMissionStep,
  isUpdatingMissingStepIntervention  : (state): boolean => state.isUpdatingMissionStepIntervention,
  isDeletingStep  : (state): boolean => state.isDeletingStep,
  requestStateStep: (state): IRequestState => state.requestStateStep,
  requestStateCommonStep: (state): IRequestState => state.requestStateCommonStep,
  isOpenSidebarStep : (state): boolean => state.isOpenSidebarStep,
  isArchivingStep : (state): boolean => state.isArchivingStep,
}