import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { CoatingTypeResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../../RootState'
import { ICoatingTypeState } from './states'


export const getters: GetterTree<ICoatingTypeState, RootState> = {
  coatingTypesList: (state): CoatingTypeResponseDto[] => state.coatingTypesList,
  coatingTypesListArchived: (state): CoatingTypeResponseDto[] => state.coatingTypesListArchived,
  coatingType     : (state): CoatingTypeResponseDto => state.coatingType,

  // COMMON
  isLoadingCoatingTypesList   : (state): boolean => state.isLoadingCoatingTypesList,
  isLoadingCoatingTypesListArchived   : (state): boolean => state.isLoadingCoatingTypesListArchived,
  isLoadingCoatingType   : (state): boolean => state.isLoadingCoatingType,
  isCreatingCoatingType  : (state): boolean => state.isCreatingCoatingType,
  isUpdatingCoatingType  : (state): boolean => state.isUpdatingCoatingType,
  isDeletingCoatingType  : (state): boolean => state.isDeletingCoatingType,
  requestStateCoatingType: (state): IRequestState => state.requestStateCoatingType,
  isOpenSidebarCoatingType : (state): boolean => state.isOpenSidebarCoatingType,
  isArchivingCoatingType : (state): boolean => state.isArchivingCoatingType,
}