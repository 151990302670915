import { PoolTypeResponseDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../../RootState'
import { PoolTypeMutations } from './mutations'
import { IPoolTypeState } from './states'

export const actions: ActionTree<IPoolTypeState, RootState> = {
  /**
   * It fetches the list of poolTypes from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchPoolTypesList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(PoolTypeMutations.SET_IS_LOADING_POOL_TYPES_LIST, true)
      commit(PoolTypeMutations.SET_IS_LOADING_POOL_TYPES_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/PoolType/GetPoolTypes',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {          
          commit(PoolTypeMutations.SET_POOL_TYPES_LIST, res.data)
          commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, res)
          commit(PoolTypeMutations.SET_IS_LOADING_POOL_TYPES_LIST, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, err)
          commit(PoolTypeMutations.SET_IS_LOADING_POOL_TYPES_LIST, false)
          reject(err)
        })
    })
  },

  async fetchPoolTypesListArchived ({ commit, rootGetters }): Promise<void> {
    commit(PoolTypeMutations.SET_IS_LOADING_POOL_TYPES_LIST_ARCHIVED, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Management/PoolType/GetPoolTypesArchived',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
        }
      )
      .then(res => {
        commit(PoolTypeMutations.SET_POOL_TYPES_LIST_ARCHIVED, res.data)
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, res)
        commit(PoolTypeMutations.SET_IS_LOADING_POOL_TYPES_LIST_ARCHIVED, false)
      })
      .catch(err => {
        console.error(err)
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, err)
        commit(PoolTypeMutations.SET_IS_LOADING_POOL_TYPES_LIST_ARCHIVED, false)
      })
  },

  async fetchPoolType (
    { commit, rootGetters },
    poolTypeId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(PoolTypeMutations.SET_IS_LOADING_POOL_TYPE, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Management/PoolType/GetPoolTypeById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                poolTypeId: poolTypeId
              }
            }
          )
          .then(res => {
            commit(PoolTypeMutations.SET_POOL_TYPE, res.data)
            commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, res)
            commit(PoolTypeMutations.SET_IS_LOADING_POOL_TYPE, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, err)
            commit(PoolTypeMutations.SET_IS_LOADING_POOL_TYPE, false)
            reject(err)
          })
    })
  },
 
  /**
   * It fetches the poolType data from the API.
   * @param  - poolTypeId: The poolTypeId of the poolType you want to fetch.
   * @param {string} poolTypeId - string
   */ 


  /**
   * It creates a new poolType.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {PoolTypeResponseDto} poolType - PoolTypeResponseDto
   */
   async createPoolType ( 
    { commit, rootGetters },
    poolType: PoolTypeResponseDto
  ): Promise<void> {

    return await new Promise((resolve, reject) => {
    commit(PoolTypeMutations.SET_IS_CREATING_POOL_TYPE, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Management/PoolType/CreatePoolType',
        poolType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.poolTypesList.unshift(res.data.data)

        commit(PoolTypeMutations.SET_POOL_TYPES_LIST, rootGetters.poolTypesList)
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, res)
        commit(PoolTypeMutations.SET_IS_CREATING_POOL_TYPE, false)
        resolve(res.data.data)
      })
      .catch(err => {
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, err)
        commit(PoolTypeMutations.SET_IS_CREATING_POOL_TYPE, false)
        reject(err)
      })
    })
  },
  /**
   * Update an poolType
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {PoolTypeResponseDto} poolType - PoolTypeResponseDto
   */
  async updatePoolType ({ commit, rootGetters }, poolType: PoolTypeResponseDto) {
    commit(PoolTypeMutations.SET_IS_UPDATING_POOL_TYPE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/PoolType/UpdatePoolType`,
        {...poolType},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.poolTypesList.splice(
          rootGetters.poolTypesList.findIndex(elem => elem.id == poolType.id),
          1,
          poolType
        )
        commit(PoolTypeMutations.SET_POOL_TYPES_LIST, rootGetters.poolTypesList)
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, res)
        commit(PoolTypeMutations.SET_IS_UPDATING_POOL_TYPE, false)
      })
      .catch(err => {
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, err)
        commit(PoolTypeMutations.SET_IS_UPDATING_POOL_TYPE, false)
      })
  },

  /**
   * It deletes an poolType from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} poolTypeId - string
   */
  async deletePoolType ({ commit, rootGetters }, poolTypeId: string) {
    commit(PoolTypeMutations.SET_IS_DELETING_POOL_TYPE, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Management/PoolType/DeletePoolType`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          poolTypeId: poolTypeId
        }
      })
      .then(res => {
        rootGetters.poolTypesListArchived.splice(
          rootGetters.poolTypesListArchived.findIndex(elem => elem.id == poolTypeId),
          1
        )
        commit(PoolTypeMutations.SET_POOL_TYPES_LIST, rootGetters.poolTypesList)
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, res)
        commit(PoolTypeMutations.SET_IS_DELETING_POOL_TYPE, false)
      })
      .catch(err => {
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, err)
        commit(PoolTypeMutations.SET_IS_DELETING_POOL_TYPE, false)
      })
  },

  async archivePoolType (
    { commit, rootGetters },
    poolTypeId: string
  ): Promise<void> {
    commit(PoolTypeMutations.SET_IS_ARCHIVING_POOL_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Management/PoolType/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[poolTypeId]
        }
      )
      .then(res => {
        rootGetters.poolTypesListArchived.unshift(rootGetters.poolTypesList.find(elem => elem.id == poolTypeId ))
        rootGetters.poolTypesList.splice(
          rootGetters.poolTypesList.findIndex(
            elem => elem.id == poolTypeId
          ),
          1
        )
        commit(
          PoolTypeMutations.SET_POOL_TYPES_LIST,
          rootGetters.poolTypesList
        )
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, res)
        commit(PoolTypeMutations.SET_IS_ARCHIVING_POOL_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, err)
        commit(PoolTypeMutations.SET_IS_ARCHIVING_POOL_TYPE, false)
      })
   },

   async restorePoolType (
    { commit, rootGetters },
    poolTypeId: string
  ): Promise<void> {
    commit(PoolTypeMutations.SET_IS_UPDATING_POOL_TYPE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Management/PoolType/Restore',
        [poolTypeId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.poolTypesList.unshift(rootGetters.poolTypesListArchived.find(elem => elem.id == poolTypeId ))
        rootGetters.poolTypesListArchived.splice(
          rootGetters.poolTypesListArchived.findIndex(
            elem => elem.id == poolTypeId
          ),
          1
        )
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, res)
        commit(PoolTypeMutations.SET_IS_UPDATING_POOL_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(PoolTypeMutations.SET_REQUEST_STATE_POOL_TYPE, err)
        commit(PoolTypeMutations.SET_IS_UPDATING_POOL_TYPE, false)
      })
   }
}
