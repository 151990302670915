import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { PoolAccessTypeResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../../RootState'
import { IPoolAccessTypeState } from './states'


export const getters: GetterTree<IPoolAccessTypeState, RootState> = {
  poolAccessTypesList: (state): PoolAccessTypeResponseDto[] => state.poolAccessTypesList,
  poolAccessTypesListArchived: (state): PoolAccessTypeResponseDto[] => state.poolAccessTypesListArchived,
  poolAccessType     : (state): PoolAccessTypeResponseDto => state.poolAccessType,

  // COMMON
  isLoadingPoolAccessTypesList   : (state): boolean => state.isLoadingPoolAccessTypesList,
  isLoadingPoolAccessTypesListArchived   : (state): boolean => state.isLoadingPoolAccessTypesListArchived,
  isLoadingPoolAccessType   : (state): boolean => state.isLoadingPoolAccessType,
  isCreatingPoolAccessType  : (state): boolean => state.isCreatingPoolAccessType,
  isUpdatingPoolAccessType  : (state): boolean => state.isUpdatingPoolAccessType,
  isDeletingPoolAccessType  : (state): boolean => state.isDeletingPoolAccessType,
  requestStatePoolAccessType: (state): IRequestState => state.requestStatePoolAccessType,
  isOpenSidebarPoolAccessType : (state): boolean => state.isOpenSidebarPoolAccessType,
  isArchivingPoolAccessType : (state): boolean => state.isArchivingPoolAccessType,
}