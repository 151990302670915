import { IFilterState } from "./states"
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IFilters } from "./iFilters"

const state:IFilterState = {
    interventionFilters: <IFilters>{}
}

export const FilterModule: Module<IFilterState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}