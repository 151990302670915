import { IAccessMethodTypeState } from "./states"
import { Module } from "vuex"
import { AccessMethodTypeResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IAccessMethodTypeState = {
    accessMethodTypesList : Array<AccessMethodTypeResponseDto>(),
    accessMethodTypesListArchived : Array<AccessMethodTypeResponseDto>(),
    accessMethodType      : <AccessMethodTypeResponseDto>{},
    isLoadingAccessMethodTypesList    : false,
    isLoadingAccessMethodTypesListArchived    : false,
    isLoadingAccessMethodType    : false,
    isCreatingAccessMethodType   : false,
    isUpdatingAccessMethodType   : false,
    isDeletingAccessMethodType   : false,
    requestStateAccessMethodType : <IRequestState>{},
    isOpenSidebarAccessMethodType : false,
    isArchivingAccessMethodType : false,
}

export const AccessMethodTypeModule: Module<IAccessMethodTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}