import { IJobState } from "./states"
import { Module } from "vuex"
import { JobRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IJobState = {
    jobsList         : Array<JobRequestDto>(),
    jobsListArchived : Array<JobRequestDto>(),
    job              : <JobRequestDto>{},
    isLoadingJobsList     : false,
    isLoadingJobsListArchived     : false,
    isLoadingJob     : false,
    isCreatingJob    : false,
    isUpdatingJob    : false,
    isDeletingJob    : false,
    isArchivingJob    : false,
    requestStateJob: <IRequestState>{},
    isOpenSidebarJob : false,
}

export const JobModule: Module<IJobState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 