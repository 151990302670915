import { DeviceResponseDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "../../RootState";
import { DeviceMutations } from "./mutations";
import { IDeviceState } from "./states";

export const actions: ActionTree<IDeviceState, RootState> = {
  /**
   * It fetches the list of devices from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchDevicesList({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(DeviceMutations.SET_IS_LOADING_DEVICES_LIST, true);
      commit(DeviceMutations.SET_IS_LOADING_DEVICES_LIST_ARCHIVED, false);
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Management/Device/GetDevices", {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          commit(DeviceMutations.SET_DEVICES_LIST, res.data);
          commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, res);
          commit(DeviceMutations.SET_IS_LOADING_DEVICES_LIST, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, err);
          commit(DeviceMutations.SET_IS_LOADING_DEVICES_LIST, false);
        });
    });
  },

  async fetchDevicesListByPoolId(
    { commit, rootGetters },
    poolId: number
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(DeviceMutations.SET_IS_LOADING_DEVICES_LIST_BY_POOL_ID, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/Device/GetDevicesByPoolId",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              poolId: poolId,
            },
          }
        )
        .then((res) => {
          commit(DeviceMutations.SET_DEVICES_LIST_BY_POOL_ID, res.data);
          commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, res);
          commit(DeviceMutations.SET_IS_LOADING_DEVICES_LIST_BY_POOL_ID, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, err);
          commit(DeviceMutations.SET_IS_LOADING_DEVICES_LIST_BY_POOL_ID, false);
          reject(err);
        });
    });
  },

  async fetchDevicesListArchived({ commit, rootGetters }): Promise<void> {
    commit(DeviceMutations.SET_IS_LOADING_DEVICES_LIST_ARCHIVED, true);
    axios
      .get(
        process.env.VUE_APP_API_ORISIS + "Management/Device/GetDevicesArchived",
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        commit(DeviceMutations.SET_DEVICES_LIST_ARCHIVED, res.data);
        commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, res);
        commit(DeviceMutations.SET_IS_LOADING_DEVICES_LIST_ARCHIVED, false);
      })
      .catch((err) => {
        console.error(err);
        commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, err);
        commit(DeviceMutations.SET_IS_LOADING_DEVICES_LIST_ARCHIVED, false);
      });
  },

  async fetchDevice({ commit, rootGetters }, deviceId: string): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(DeviceMutations.SET_IS_LOADING_DEVICE, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Management/Device/GetDeviceById",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              deviceId: deviceId,
            },
          }
        )
        .then((res) => {
          commit(DeviceMutations.SET_DEVICE, res.data);
          commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, res);
          commit(DeviceMutations.SET_IS_LOADING_DEVICE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, err);
          commit(DeviceMutations.SET_IS_LOADING_DEVICE, false);
          reject(err);
        });
    });
  },

  /**
   * It fetches the device data from the API.
   * @param  - deviceId: The deviceId of the device you want to fetch.
   * @param {string} deviceId - string
   */
  async fetchDeviceArchived(
    { commit, rootGetters },
    deviceId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(DeviceMutations.SET_IS_LOADING_DEVICE, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/Device/GetDeviceArchivedById",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              deviceId: deviceId,
            },
          }
        )
        .then((res) => {
          commit(DeviceMutations.SET_DEVICE, res.data);
          commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, res);
          commit(DeviceMutations.SET_IS_LOADING_DEVICE, false);
        })
        .catch((err) => {
          console.error(err);
          commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, err);
          commit(DeviceMutations.SET_IS_LOADING_DEVICE, false);
        });
    });
  },

  /**
   * It creates a new device.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {DeviceResponseDto} device - DeviceResponseDto
   */
  async createDevice(
    { commit, rootGetters },
    device: DeviceResponseDto
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(DeviceMutations.SET_IS_CREATING_DEVICE, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Management/Device/CreateDevice",
          device,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
          }
        )
        .then((res) => {
          rootGetters.devicesList.unshift(res.data.data);

          commit(DeviceMutations.SET_DEVICES_LIST, rootGetters.devicesList);
          commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, res);
          commit(DeviceMutations.SET_IS_CREATING_DEVICE, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, err);
          commit(DeviceMutations.SET_IS_CREATING_DEVICE, false);
          reject(err);
        });
    });
  },
  /**
   * Update an device
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {DeviceResponseDto} device - DeviceResponseDto
   */
  async updateDevice({ commit, rootGetters }, device: DeviceResponseDto) {
    commit(DeviceMutations.SET_IS_UPDATING_DEVICE, true);
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/Device/UpdateDevice`,
        { ...device },
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        rootGetters.devicesList.splice(
          rootGetters.devicesList.findIndex((elem) => elem.id == device.id),
          1,
          device
        );
        commit(DeviceMutations.SET_DEVICES_LIST, rootGetters.devicesList);
        commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, res);
        commit(DeviceMutations.SET_IS_UPDATING_DEVICE, false);
      })
      .catch((err) => {
        commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, err);
        commit(DeviceMutations.SET_IS_UPDATING_DEVICE, false);
      });
  },

  /**
   * It deletes an device from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} deviceId - string
   */
  async deleteDevice({ commit, rootGetters }, deviceId: string) {
    commit(DeviceMutations.SET_IS_DELETING_DEVICE, true);
    await axios
      .delete(
        `${process.env.VUE_APP_API_ORISIS}Management/Device/DeleteDevice`,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            deviceId: deviceId,
          },
        }
      )
      .then((res) => {
        rootGetters.devicesListArchived.splice(
          rootGetters.devicesListArchived.findIndex(
            (elem) => elem.id == deviceId
          ),
          1
        );
        commit(DeviceMutations.SET_DEVICES_LIST, rootGetters.devicesList);
        commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, res);
        commit(DeviceMutations.SET_IS_DELETING_DEVICE, false);
      })
      .catch((err) => {
        commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, err);
        commit(DeviceMutations.SET_IS_DELETING_DEVICE, false);
      });
  },

  async archiveDevice(
    { commit, rootGetters },
    deviceId: string
  ): Promise<void> {
    commit(DeviceMutations.SET_IS_ARCHIVING_DEVICE, true);
    axios
      .delete(process.env.VUE_APP_API_ORISIS + "Management/Device/Archive", {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`,
        },
        data: [deviceId],
      })
      .then((res) => {
        rootGetters.devicesListArchived.unshift(
          rootGetters.devicesList.find((elem) => elem.id == deviceId)
        );
        rootGetters.devicesList.splice(
          rootGetters.devicesList.findIndex((elem) => elem.id == deviceId),
          1
        );
        commit(DeviceMutations.SET_DEVICES_LIST, rootGetters.devicesList);
        commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, res);
        commit(DeviceMutations.SET_IS_ARCHIVING_DEVICE, false);
      })
      .catch((err) => {
        console.error(err);
        commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, err);
        commit(DeviceMutations.SET_IS_ARCHIVING_DEVICE, false);
      });
  },

  async restoreDevice(
    { commit, rootGetters },
    deviceId: string
  ): Promise<void> {
    commit(DeviceMutations.SET_IS_UPDATING_DEVICE, true);
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + "Management/Device/Restore",
        [deviceId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {
        rootGetters.devicesList.unshift(
          rootGetters.devicesListArchived.find((elem) => elem.id == deviceId)
        );
        rootGetters.devicesListArchived.splice(
          rootGetters.devicesListArchived.findIndex(
            (elem) => elem.id == deviceId
          ),
          1
        );
        commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, res);
        commit(DeviceMutations.SET_IS_UPDATING_DEVICE, false);
      })
      .catch((err) => {
        console.error(err);
        commit(DeviceMutations.SET_REQUEST_STATE_DEVICE, err);
        commit(DeviceMutations.SET_IS_UPDATING_DEVICE, false);
      });
  },
};
