import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "../../RootState";
import { InterventionStatisticsMutations } from "./mutations";
import { IInterventionStatisticsState } from "./states";

export const actions: ActionTree<IInterventionStatisticsState, RootState> = {
  async fetchStatsInterventions(
    { commit, rootGetters },
    { startDate, endDate }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        InterventionStatisticsMutations.SET_IS_LOADING_STATS_INTERVENTIONS,
        true
      );
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Management/InterventionStatistics/GetStatsInterventions",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              startDate: startDate,
              endDate: endDate,
            },
          }
        )
        .then((res) => {
          commit(
            InterventionStatisticsMutations.SET_STATS_INTERVENTIONS,
            res.data
          );
          commit(
            InterventionStatisticsMutations.SET_IS_LOADING_STATS_INTERVENTIONS,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InterventionStatisticsMutations.SET_IS_LOADING_STATS_INTERVENTIONS,
            false
          );
          reject(err);
        });
    });
  },
};
