import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CollaboratorRequestDto } from '@/../Api'
import RootState from "@/store/modules/RootState"
import { ICollaboratorState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: ICollaboratorState = {
    collaboratorsList       : Array<CollaboratorRequestDto>(),
    collaboratorsListArchived : Array<CollaboratorRequestDto>(),
    collaborator            : <CollaboratorRequestDto>{},
    isLoadingCollaboratorsList   : false,
    isLoadingCollaboratorsListArchived   : false,
    isLoadingCollaborator   : false,
    isCreatingCollaborator  : false,
    isUpdatingCollaborator  : false,
    isDeletingCollaborator  : false,
    isArchivingCollaborator : false,
    requestStateCollaborator: <IRequestState>{},
    isOpenSidebarCollaborator : false,
}

export const CollaboratorModule: Module<ICollaboratorState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}