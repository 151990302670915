import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { JobRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { IJobState } from './states'


export const getters: GetterTree<IJobState, RootState> = {
  jobsList: (state): JobRequestDto[] => state.jobsList,
  jobsListArchived: (state): JobRequestDto[] => state.jobsListArchived,
  job     : (state): JobRequestDto => state.job,

  // COMMON
  isLoadingJobsList   : (state): boolean => state.isLoadingJobsList,
  isLoadingJobsListArchived   : (state): boolean => state.isLoadingJobsListArchived,
  isLoadingJob   : (state): boolean => state.isLoadingJob,
  isCreatingJob  : (state): boolean => state.isCreatingJob,
  isUpdatingJob  : (state): boolean => state.isUpdatingJob,
  isDeletingJob  : (state): boolean => state.isDeletingJob,
  isArchivingJob  : (state): boolean => state.isArchivingJob,
  requestStateJob: (state): IRequestState => state.requestStateJob,
  isOpenSidebarJob : (state): boolean => state.isOpenSidebarJob,
}



