import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { CustomerResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { ICustomerState } from './states'


export const getters: GetterTree<ICustomerState, RootState> = {
  customersList: (state): CustomerResponseDto[] => state.customersList,
  customersListArchived: (state): CustomerResponseDto[] => state.customersListArchived,
  customer     : (state): CustomerResponseDto => state.customer,

  // COMMON
  isLoadingCustomersList   : (state): boolean => state.isLoadingCustomersList,
  isLoadingCustomersListArchived   : (state): boolean => state.isLoadingCustomersListArchived,
  isLoadingCustomer   : (state): boolean => state.isLoadingCustomer,
  isCreatingCustomer  : (state): boolean => state.isCreatingCustomer,
  isUpdatingCustomer  : (state): boolean => state.isUpdatingCustomer,
  isDeletingCustomer  : (state): boolean => state.isDeletingCustomer,
  requestStateCustomer: (state): IRequestState => state.requestStateCustomer,
  isOpenSidebarCustomer : (state): boolean => state.isOpenSidebarCustomer,
  isArchivingCustomer : (state): boolean => state.isArchivingCustomer,
}