import { DeviceTypeResponseDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../../RootState'
import { DeviceTypeMutations } from './mutations'
import { IDeviceTypeState } from './states'

export const actions: ActionTree<IDeviceTypeState, RootState> = {
  /**
   * It fetches the list of deviceTypes from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchDeviceTypesList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPES_LIST, true)
      commit(DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPES_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/DeviceType/GetDeviceTypes',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(DeviceTypeMutations.SET_DEVICE_TYPES_LIST, res.data)
          commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, res)
          commit(DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPES_LIST, false)
          resolve(res.data)

        })
        .catch(err => {
          console.error(err)
          commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, err)
          commit(DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPES_LIST, false)
          reject(err)
        })
    })
  },

  async fetchDeviceTypesListArchived ({ commit, rootGetters }): Promise<void> {
    commit(DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPES_LIST_ARCHIVED, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Management/DeviceType/GetDeviceTypesArchived',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
        }
      )
      .then(res => {
        commit(DeviceTypeMutations.SET_DEVICE_TYPES_LIST_ARCHIVED, res.data)
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, res)
        commit(DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPES_LIST_ARCHIVED, false)
      })
      .catch(err => {
        console.error(err)
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, err)
        commit(DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPES_LIST_ARCHIVED, false)
      })
  },

  async fetchDeviceType (
    { commit, rootGetters },
    deviceTypeId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPE, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Management/DeviceType/GetDeviceTypeById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                deviceTypeId: deviceTypeId
              }
            }
          )
          .then(res => {
            commit(DeviceTypeMutations.SET_DEVICE_TYPE, res.data)
            commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, res)
            commit(DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPE, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, err)
            commit(DeviceTypeMutations.SET_IS_LOADING_DEVICE_TYPE, false)
            reject(err)
          })
    })
  },

  /**
   * It creates a new deviceType.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {DeviceTypeResponseDto} deviceType - DeviceTypeResponseDto
   */
   async createDeviceType ( 
    { commit, rootGetters },
    deviceType: DeviceTypeResponseDto
  ): Promise<void> {

    return await new Promise((resolve, reject) => {
    commit(DeviceTypeMutations.SET_IS_CREATING_DEVICE_TYPE, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Management/DeviceType/CreateDeviceType',
        deviceType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.deviceTypesList.unshift(res.data.data)

        commit(DeviceTypeMutations.SET_DEVICE_TYPES_LIST, rootGetters.deviceTypesList)
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, res)
        commit(DeviceTypeMutations.SET_IS_CREATING_DEVICE_TYPE, false)
        resolve(res.data.data)
      })
      .catch(err => {
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, err)
        commit(DeviceTypeMutations.SET_IS_CREATING_DEVICE_TYPE, false)
        reject(err)
      })
    })
  },
  /**
   * Update an deviceType
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {DeviceTypeResponseDto} deviceType - DeviceTypeResponseDto
   */
  async updateDeviceType ({ commit, rootGetters }, deviceType: DeviceTypeResponseDto) {
    commit(DeviceTypeMutations.SET_IS_UPDATING_DEVICE_TYPE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/DeviceType/UpdateDeviceType`,
        {deviceType},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.deviceTypesList.splice(
          rootGetters.deviceTypesList.findIndex(elem => elem.id == deviceType.id),
          1,
          deviceType
        )
        commit(DeviceTypeMutations.SET_DEVICE_TYPES_LIST, rootGetters.deviceTypesList)
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, res)
        commit(DeviceTypeMutations.SET_IS_UPDATING_DEVICE_TYPE, false)
      })
      .catch(err => {
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, err)
        commit(DeviceTypeMutations.SET_IS_UPDATING_DEVICE_TYPE, false)
      })
  },

  /**
   * It deletes an deviceType from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} deviceTypeId - string
   */
  async deleteDeviceType ({ commit, rootGetters }, deviceTypeId: string) {
    commit(DeviceTypeMutations.SET_IS_DELETING_DEVICE_TYPE, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Management/DeviceType/DeleteDeviceType`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          deviceTypeId: deviceTypeId
        }
      })
      .then(res => {
        rootGetters.deviceTypesListArchived.splice(
          rootGetters.deviceTypesListArchived.findIndex(elem => elem.id == deviceTypeId),
          1
        )
        commit(DeviceTypeMutations.SET_DEVICE_TYPES_LIST, rootGetters.deviceTypesList)
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, res)
        commit(DeviceTypeMutations.SET_IS_DELETING_DEVICE_TYPE, false)
      })
      .catch(err => {
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, err)
        commit(DeviceTypeMutations.SET_IS_DELETING_DEVICE_TYPE, false)
      })
  },

  async archiveDeviceType (
    { commit, rootGetters },
    deviceTypeId: string
  ): Promise<void> {
    commit(DeviceTypeMutations.SET_IS_ARCHIVING_DEVICE_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Management/DeviceType/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[deviceTypeId]
        }
      )
      .then(res => {
        rootGetters.deviceTypesListArchived.unshift(rootGetters.deviceTypesList.find(elem => elem.id == deviceTypeId ))
        rootGetters.deviceTypesList.splice(
          rootGetters.deviceTypesList.findIndex(
            elem => elem.id == deviceTypeId
          ),
          1
        )
        commit(
          DeviceTypeMutations.SET_DEVICE_TYPES_LIST,
          rootGetters.deviceTypesList
        )
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, res)
        commit(DeviceTypeMutations.SET_IS_ARCHIVING_DEVICE_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, err)
        commit(DeviceTypeMutations.SET_IS_ARCHIVING_DEVICE_TYPE, false)
      })
   },

   async restoreDeviceType (
    { commit, rootGetters },
    deviceTypeId: string
  ): Promise<void> {
    commit(DeviceTypeMutations.SET_IS_UPDATING_DEVICE_TYPE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Management/DeviceType/Restore',
        [deviceTypeId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.deviceTypesList.unshift(rootGetters.deviceTypesListArchived.find(elem => elem.id == deviceTypeId ))
        rootGetters.deviceTypesListArchived.splice(
          rootGetters.deviceTypesListArchived.findIndex(
            elem => elem.id == deviceTypeId
          ),
          1
        )
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, res)
        commit(DeviceTypeMutations.SET_IS_UPDATING_DEVICE_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(DeviceTypeMutations.SET_REQUEST_STATE_DEVICE_TYPE, err)
        commit(DeviceTypeMutations.SET_IS_UPDATING_DEVICE_TYPE, false)
      })
   }
}
