import { JobRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { JobMutations } from './mutations'
import { IJobState } from './states'

export const actions: ActionTree<IJobState, RootState> = {
  /**
   * It fetches the list of jobs from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchJobsList ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(JobMutations.SET_IS_LOADING_JOB_LIST, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Parameters/Job/GetJobs`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
      })
      .then(res => {
        commit(JobMutations.SET_IS_LOADING_JOB_LIST, false)
        commit(JobMutations.SET_JOBS_LIST, res.data)
        commit(JobMutations.SET_REQUEST_STATE_JOB, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(JobMutations.SET_IS_LOADING_JOB_LIST, false)
        commit(JobMutations.SET_REQUEST_STATE_JOB, err)
        reject(err)
      })
   })
  },
  async fetchJobsListArchived ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(JobMutations.SET_IS_LOADING_JOB_LIST_ARCHIVED, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Parameters/Job/GetJobsArchived`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
      })
      .then(res => {
        commit(JobMutations.SET_IS_LOADING_JOB_LIST_ARCHIVED, false)
        commit(JobMutations.SET_JOBS_LIST_ARCHIVED, res.data)
        commit(JobMutations.SET_REQUEST_STATE_JOB, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(JobMutations.SET_IS_LOADING_JOB_LIST_ARCHIVED, false)
        commit(JobMutations.SET_REQUEST_STATE_JOB, err)
        reject(err)
      })
   })
  },

  /* Fetching the job data from the API. */
  async fetchJob ({ commit, rootGetters }, jobId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(JobMutations.SET_IS_LOADING_JOB, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Parameters/Job/GetJobById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          jobId: jobId
        }
      })
      .then(res => {
        commit(JobMutations.SET_JOB, res.data)
        commit(JobMutations.SET_REQUEST_STATE_JOB, res)
        commit(JobMutations.SET_IS_LOADING_JOB, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(JobMutations.SET_REQUEST_STATE_JOB, err)
        commit(JobMutations.SET_IS_LOADING_JOB, false)
        reject(err)
      })
    })
  },

  async fetchJobArchived (
    { commit, rootGetters },
    jobId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(JobMutations.SET_IS_LOADING_JOB, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Parameters/Job/GetJobArchivedById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          jobId: jobId
        }
      })
      .then(res => {
        commit(JobMutations.SET_JOB, res.data)
        commit(JobMutations.SET_REQUEST_STATE_JOB, res)
        commit(JobMutations.SET_IS_LOADING_JOB, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(JobMutations.SET_REQUEST_STATE_JOB, err)
        commit(JobMutations.SET_IS_LOADING_JOB, false)
        reject(err)
      })
    })
  },

  /**
   * It creates a new job.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {JobRequestDto} job - JobRequestDto
   */
  async createJob ({ commit, rootGetters }, job: JobRequestDto): Promise<void> {
    commit(JobMutations.SET_IS_CREATING_JOB, true)
    await axios
      .post(
        `${process.env.VUE_APP_API_ORISIS}Parameters/Job/CreateJob`,
        job,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.jobsList.unshift(res.data.data)
        commit(
          JobMutations.SET_JOBS_LIST,
          rootGetters.jobsList
        )
        commit(JobMutations.SET_REQUEST_STATE_JOB, res)
        commit(JobMutations.SET_IS_CREATING_JOB, false)
      })
      .catch(err => {
        commit(JobMutations.SET_REQUEST_STATE_JOB, err)
        commit(JobMutations.SET_IS_CREATING_JOB, false)
      })
  },

  /**
   * Update an job
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {JobRequestDto} job - JobRequestDto
   */
  async updateJob ({ commit, rootGetters }, job: JobRequestDto) {
    commit(JobMutations.SET_IS_UPDATING_JOB, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Parameters/Job/UpdateJob`,
        {...job,workspaceId: rootGetters.workspaceSelected.id},  
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.jobsList.splice(
          rootGetters.jobsList.findIndex(elem => elem.id == job.id),
          1,
          job
        )
        commit(
          JobMutations.SET_JOBS_LIST,
          rootGetters.jobsList
        )
        commit(JobMutations.SET_REQUEST_STATE_JOB, res)
        commit(JobMutations.SET_IS_UPDATING_JOB, false)
      })
      .catch(err => {
        commit(JobMutations.SET_REQUEST_STATE_JOB, err)
        commit(JobMutations.SET_IS_UPDATING_JOB, false)
      })
  },

  /**
   * It deletes an job from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} jobId - string
   */
  async deleteJob ({ commit, rootGetters }, jobId: string) {
    commit(JobMutations.SET_IS_DELETING_JOB, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Parameters/Job/Delete`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          jobId: jobId
        }
      })
      .then(res => {
        rootGetters.jobsList.splice(
          rootGetters.jobsList.findIndex(elem => elem.id == jobId),
          1
        )
        commit(
          JobMutations.SET_JOBS_LIST,
          rootGetters.jobsList
        )
        commit(JobMutations.SET_REQUEST_STATE_JOB, res)
        commit(JobMutations.SET_IS_DELETING_JOB, false)
      })
      .catch(err => {
        commit(JobMutations.SET_REQUEST_STATE_JOB, err)
        commit(JobMutations.SET_IS_DELETING_JOB, false)
      })
  },

  /**
   * Delete a job from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} jobId - The id of the job to delete.
   */
  async archiveJob (
    { commit, rootGetters },
    jobId: string
  ): Promise<void> {
    commit(JobMutations.SET_IS_ARCHIVING_JOB, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Parameters/Job/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[jobId]
        }
      )
      .then(res => {
        rootGetters.jobsListArchived.unshift(rootGetters.jobsList.find(elem => elem.id == jobId ))
        rootGetters.jobsList.splice(
          rootGetters.jobsList.findIndex(
            elem => elem.id == jobId
          ),
          1
        )
        commit(
          JobMutations.SET_JOBS_LIST,
          rootGetters.jobsList
        )
        commit(JobMutations.SET_REQUEST_STATE_JOB, res)
        commit(JobMutations.SET_IS_ARCHIVING_JOB, false)
      })
      .catch(err => {
        console.error(err)
        commit(JobMutations.SET_REQUEST_STATE_JOB, err)
        commit(JobMutations.SET_IS_ARCHIVING_JOB, false)
      })
   },

  async restoreJob (
    { commit, rootGetters },
    jobId: string
  ): Promise<void> {
    commit(JobMutations.SET_IS_UPDATING_JOB, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Parameters/Job/Restore',
        [jobId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.jobsList.unshift(rootGetters.jobsListArchived.find(elem => elem.id == jobId ))
        rootGetters.jobsListArchived.splice(
          rootGetters.jobsListArchived.findIndex(
            elem => elem.id == jobId
          ),
          1
        )
        commit(JobMutations.SET_REQUEST_STATE_JOB, res)
        commit(JobMutations.SET_IS_UPDATING_JOB, false)
      })
      .catch(err => {
        console.error(err)
        commit(JobMutations.SET_REQUEST_STATE_JOB, err)
        commit(JobMutations.SET_IS_UPDATING_JOB, false)
      })
   }
}
