import { CustomerResponseDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { CustomerMutations } from './mutations'
import { ICustomerState } from './states'

export const actions: ActionTree<ICustomerState, RootState> = {
  /**
   * It fetches the list of customers from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchCustomersList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(CustomerMutations.SET_IS_LOADING_CUSTOMERS_LIST, true)
      commit(CustomerMutations.SET_IS_LOADING_CUSTOMERS_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Directory/Customer/GetCustomers',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(CustomerMutations.SET_CUSTOMERS_LIST, res.data)
          commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, res)
          commit(CustomerMutations.SET_IS_LOADING_CUSTOMERS_LIST, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, err)
          commit(CustomerMutations.SET_IS_LOADING_CUSTOMERS_LIST, false)
        })
    })
  },

  async fetchCustomersListArchived ({ commit, rootGetters }): Promise<void> {
    commit(CustomerMutations.SET_IS_LOADING_CUSTOMERS_LIST_ARCHIVED, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Directory/Customer/GetCustomersArchived',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
        }
      )
      .then(res => {
        commit(CustomerMutations.SET_CUSTOMERS_LIST_ARCHIVED, res.data)
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, res)
        commit(CustomerMutations.SET_IS_LOADING_CUSTOMERS_LIST_ARCHIVED, false)
      })
      .catch(err => {
        console.error(err)
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, err)
        commit(CustomerMutations.SET_IS_LOADING_CUSTOMERS_LIST_ARCHIVED, false)
      })
  },

  async fetchCustomer (
    { commit, rootGetters },
    customerId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(CustomerMutations.SET_IS_LOADING_CUSTOMER, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Directory/Customer/GetCustomerById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                customerId: customerId
              }
            }
          )
          .then(res => {
            commit(CustomerMutations.SET_CUSTOMER, res.data)
            commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, res)
            commit(CustomerMutations.SET_IS_LOADING_CUSTOMER, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, err)
            commit(CustomerMutations.SET_IS_LOADING_CUSTOMER, false)
            reject(err)
          })
    })
  },
 
  /**
   * It fetches the customer data from the API.
   * @param  - customerId: The customerId of the customer you want to fetch.
   * @param {string} customerId - string
   */ 
  async fetchCustomerArchived (
    { commit, rootGetters },
    customerId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(CustomerMutations.SET_IS_LOADING_CUSTOMER, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Directory/Customer/GetCustomerArchivedById',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              customerId: customerId
            }
          }
        )
        .then(res => {
          commit(CustomerMutations.SET_CUSTOMER, res.data)
          commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, res)
          commit(CustomerMutations.SET_IS_LOADING_CUSTOMER, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, err)
          commit(CustomerMutations.SET_IS_LOADING_CUSTOMER, false)
          reject(err)
        })
    })
  },


  /**
   * It creates a new customer.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {CustomerResponseDto} customer - CustomerResponseDto
   */
   async createCustomer ( 
    { commit, rootGetters },
    customer: CustomerResponseDto
  ): Promise<void> {
    commit(CustomerMutations.SET_IS_CREATING_CUSTOMER, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Directory/Customer/CreateCustomer',
        customer,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.customersList.unshift(res.data.data)
        commit(CustomerMutations.SET_CUSTOMERS_LIST, rootGetters.customersList)
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, res)
        commit(CustomerMutations.SET_IS_CREATING_CUSTOMER, false)
      })
      .catch(err => {
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, err)
        commit(CustomerMutations.SET_IS_CREATING_CUSTOMER, false)
      })
  },
  /**
   * Update an customer
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {CustomerResponseDto} customer - CustomerResponseDto
   */
  async updateCustomer ({ commit, rootGetters }, customer: CustomerResponseDto) {
    commit(CustomerMutations.SET_IS_UPDATING_CUSTOMER, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Directory/Customer/UpdateCustomer`,
        {...customer},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.customersList.splice(
          rootGetters.customersList.findIndex(elem => elem.id == customer.id),
          1,
          customer
        )
        commit(CustomerMutations.SET_CUSTOMERS_LIST, rootGetters.customersList)
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, res)
        commit(CustomerMutations.SET_IS_UPDATING_CUSTOMER, false)
      })
      .catch(err => {
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, err)
        commit(CustomerMutations.SET_IS_UPDATING_CUSTOMER, false)
      })
  },

  /**
   * It deletes an customer from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} customerId - string
   */
  async deleteCustomer ({ commit, rootGetters }, customerId: string) {
    commit(CustomerMutations.SET_IS_DELETING_CUSTOMER, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Directory/Customer/DeleteCustomer`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          customerId: customerId
        }
      })
      .then(res => {
        rootGetters.customersListArchived.splice(
          rootGetters.customersListArchived.findIndex(elem => elem.id == customerId),
          1
        )
        commit(CustomerMutations.SET_CUSTOMERS_LIST, rootGetters.customersList)
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, res)
        commit(CustomerMutations.SET_IS_DELETING_CUSTOMER, false)
      })
      .catch(err => {
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, err)
        commit(CustomerMutations.SET_IS_DELETING_CUSTOMER, false)
      })
  },

  async archiveCustomer (
    { commit, rootGetters },
    customerId: string
  ): Promise<void> {
    commit(CustomerMutations.SET_IS_ARCHIVING_CUSTOMER, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Directory/Customer/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[customerId]
        }
      )
      .then(res => {
        rootGetters.customersListArchived.unshift(rootGetters.customersList.find(elem => elem.id == customerId ))
        rootGetters.customersList.splice(
          rootGetters.customersList.findIndex(
            elem => elem.id == customerId
          ),
          1
        )
        commit(
          CustomerMutations.SET_CUSTOMERS_LIST,
          rootGetters.customersList
        )
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, res)
        commit(CustomerMutations.SET_IS_ARCHIVING_CUSTOMER, false)
      })
      .catch(err => {
        console.error(err)
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, err)
        commit(CustomerMutations.SET_IS_ARCHIVING_CUSTOMER, false)
      })
  },

  async restoreCustomer (
    { commit, rootGetters },
    customerId: string
  ): Promise<void> {
    commit(CustomerMutations.SET_IS_UPDATING_CUSTOMER, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Directory/Customer/Restore',
        [customerId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.customersList.unshift(rootGetters.customersListArchived.find(elem => elem.id == customerId ))
        rootGetters.customersListArchived.splice(
          rootGetters.customersListArchived.findIndex(
            elem => elem.id == customerId
          ),
          1
        )
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, res)
        commit(CustomerMutations.SET_IS_UPDATING_CUSTOMER, false)
      })
      .catch(err => {
        console.error(err)
        commit(CustomerMutations.SET_REQUEST_STATE_CUSTOMER, err)
        commit(CustomerMutations.SET_IS_UPDATING_CUSTOMER, false)
      })
  },
}
