import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { DeviceResponseDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import { IDeviceState } from "./states";

export const getters: GetterTree<IDeviceState, RootState> = {
  devicesList: (state): DeviceResponseDto[] => state.devicesList,
  devicesListByPoolId: (state): DeviceResponseDto[] =>
    state.devicesListByPoolId,
  devicesListArchived: (state): DeviceResponseDto[] =>
    state.devicesListArchived,
  device: (state): DeviceResponseDto => state.device,

  // COMMON
  isLoadingDevicesList: (state): boolean => state.isLoadingDevicesList,
  isLoadingDevicesListArchived: (state): boolean =>
    state.isLoadingDevicesListArchived,
  isLoadingDevicesListByPoolId: (state): boolean =>
    state.isLoadingDevicesListByPoolId,
  isLoadingDevice: (state): boolean => state.isLoadingDevice,
  isCreatingDevice: (state): boolean => state.isCreatingDevice,
  isUpdatingDevice: (state): boolean => state.isUpdatingDevice,
  isDeletingDevice: (state): boolean => state.isDeletingDevice,
  requestStateDevice: (state): IRequestState => state.requestStateDevice,
  isOpenSidebarDevice: (state): boolean => state.isOpenSidebarDevice,
  isArchivingDevice: (state): boolean => state.isArchivingDevice,
};
