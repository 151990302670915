import { FileResponseDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "../../RootState";
import { FileMutations } from "./mutations";
import { IFileState } from "./states";

export const actions: ActionTree<IFileState, RootState> = {
  /**
   * It fetches the list of files from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchFilesList({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(FileMutations.SET_IS_LOADING_FILES_LIST, true);
      commit(
        FileMutations.SET_IS_LOADING_FILES_LIST_ARCHIVED,
        false
      );
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Ged/File/GetFiles',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              workspaceId: rootGetters.workspaceSelected.id,
            },
          }
        )
        .then((res) => {
          commit(FileMutations.SET_FILES_LIST, res.data);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(
            FileMutations.SET_IS_LOADING_FILES_LIST,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(
            FileMutations.SET_IS_LOADING_FILES_LIST,
            false
          );
        });
    });
  },


  async fetchFile(
    { commit, rootGetters },
    fileId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(FileMutations.SET_IS_LOADING_FILE, true);
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Ged/File/GetFileById",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              fileId: fileId,
            },
          }
        )
        .then((res) => {
          const result = res.data
          for (let i = 0; i < result.steps.length; i++) {
            const step = result.steps[i];
            step.missions = result.missions.filter(elem => elem.stepId == step.step.id)
          }
          commit(FileMutations.SET_FILE, result);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_LOADING_FILE, false);

          resolve(
            res.data,
);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_LOADING_FILE, false);
        });
    });
  },


  /**
   * Update an file
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {FileResponseDto} file - FileResponseDto
   */
  async updateFileIndex(
    { commit, rootGetters },
    file: FileResponseDto
  ) {
    return await new Promise((resolve, reject) => {
    commit(FileMutations.SET_IS_UPDATING_FILE, true);
    axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Ged/File/UpdateFileIndex`,
        { ...file},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        }
      )
      .then((res) => {        
        rootGetters.filesList.splice(
          rootGetters.filesList.findIndex(
            (elem) => elem.id == file.id
          ),
          1,
          res.data.data
        );
        commit(
          FileMutations.SET_FILES_LIST,
          rootGetters.filesList
        );
        commit(FileMutations.SET_REQUEST_STATE_FILE, res);
        commit(FileMutations.SET_IS_UPDATING_FILE, false);
        resolve(res.data.data)
      })
      .catch((err) => {
        commit(FileMutations.SET_REQUEST_STATE_FILE, err);
        commit(FileMutations.SET_IS_UPDATING_FILE, false);
        reject(err)
      });
    })
  },
  

  /**
   * It deletes an file from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} fileId - string
   */
  async deleteFile({ commit, rootGetters }, fileId: string) {
    commit(FileMutations.SET_IS_DELETING_FILE, true);
    await axios
      .delete(
        `${process.env.VUE_APP_API_ORISIS}Ged/File/DeleteFiles`,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          data: [fileId],
        }
      )
      .then((res) => {
        rootGetters.filesListArchived.splice(
          rootGetters.filesListArchived.findIndex(
            (elem) => elem.id == fileId
          ),
          1
        );
        commit(
          FileMutations.SET_FILES_LIST,
          rootGetters.filesList
        );
        commit(FileMutations.SET_REQUEST_STATE_FILE, res);
        commit(FileMutations.SET_IS_DELETING_FILE, false);
      })
      .catch((err) => {
        commit(FileMutations.SET_REQUEST_STATE_FILE, err);
        commit(FileMutations.SET_IS_DELETING_FILE, false);
      });
  },

  async archiveFile(
    { commit, rootGetters },
    fileId: string
  ): Promise<void> {
    commit(FileMutations.SET_IS_ARCHIVING_FILE, true);
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + "Ged/File/Archive",
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          data: [fileId],
        }
      )
      .then((res) => {
        rootGetters.filesListArchived.unshift(
          rootGetters.filesList.find(
            (elem) => elem.id == fileId
          )
        );
        rootGetters.filesList.splice(
          rootGetters.filesList.findIndex(
            (elem) => elem.id == fileId
          ),
          1
        );
        commit(
          FileMutations.SET_FILES_LIST,
          rootGetters.filesList
        );
        commit(FileMutations.SET_REQUEST_STATE_FILE, res);
        commit(FileMutations.SET_IS_ARCHIVING_FILE, false);
      })
      .catch((err) => {
        console.error(err);
        commit(FileMutations.SET_REQUEST_STATE_FILE, err);
        commit(FileMutations.SET_IS_ARCHIVING_FILE, false);
      });
  },
};
