import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { PoolTypeResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../../RootState'
import { IPoolTypeState } from './states'


export const getters: GetterTree<IPoolTypeState, RootState> = {
  poolTypesList: (state): PoolTypeResponseDto[] => state.poolTypesList,
  poolTypesListArchived: (state): PoolTypeResponseDto[] => state.poolTypesListArchived,
  poolType     : (state): PoolTypeResponseDto => state.poolType,

  // COMMON
  isLoadingPoolTypesList   : (state): boolean => state.isLoadingPoolTypesList,
  isLoadingPoolTypesListArchived   : (state): boolean => state.isLoadingPoolTypesListArchived,
  isLoadingPoolType   : (state): boolean => state.isLoadingPoolType,
  isCreatingPoolType  : (state): boolean => state.isCreatingPoolType,
  isUpdatingPoolType  : (state): boolean => state.isUpdatingPoolType,
  isDeletingPoolType  : (state): boolean => state.isDeletingPoolType,
  requestStatePoolType: (state): IRequestState => state.requestStatePoolType,
  isOpenSidebarPoolType : (state): boolean => state.isOpenSidebarPoolType,
  isArchivingPoolType : (state): boolean => state.isArchivingPoolType,
}